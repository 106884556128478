import styled from 'styled-components';

import { Menu } from '@mui/material';

export const MenuWrapper = styled(Menu)`
  span,
  svg {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }

  svg {
    width: 0.9em !important;
  }
`;
