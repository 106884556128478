import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../utils';
import { getAriaLabel } from '../a11y';

class Input extends PureComponent {
  constructor(props) {
    super(props);
    const { onInputChange } = this.props;
    this.delayedCallback = debounce((e) => onInputChange(e.target.value), 10);
  }

  handleInputChange = (e) => {
    e.persist();
    this.delayedCallback(e);
  };

  render() {
    const {
      inputRef,
      texts = {},
      onFocus,
      onBlur,
      disabled,
      readOnly,
      onKeyDown,
      onKeyUp,
      activeDescendant,
      autoFocus,
      dataTestId
    } = this.props;

    return (
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        type="text"
        disabled={disabled}
        ref={inputRef}
        className="search"
        placeholder={texts.inlineSearchPlaceholder || 'Search...'}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={this.handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        readOnly={readOnly}
        aria-activedescendant={activeDescendant}
        aria-autocomplete={onKeyDown ? 'list' : undefined}
        {...getAriaLabel(texts.label)}
        data-testid={dataTestId ? `${dataTestId}-search` : undefined}
      />
    );
  }
}

Input.propTypes = {
  texts: PropTypes.shape({
    placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onTagRemove: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  inputRef: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  activeDescendant: PropTypes.string,
  autoFocus: PropTypes.bool,
  dataTestId: PropTypes.string
};

export default Input;
