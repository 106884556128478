import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSitesByGroups(filters) {
  const client = eficiaClient();

  const fetcher = useCallback(() => {
    const params = {};

    if (!filters) {
      return null;
    }

    if (filters.groupIds?.length > 0) {
      params.groupIds = filters.groupIds;
      params.intersect = filters.operator === 'and';
    }

    return client.sites.fetchFullByGroupId(params);
  }, [client.sites, filters]);

  const { data, error, isFetching, refetch } = useQuery(
    ['sites-by-groups-help-button'],
    fetcher
  );

  return {
    data: data?.data || null,
    error,
    isFetching,
    refetch
  };
}
