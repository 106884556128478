import { SITES_API, EFICIA_BASE_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'groups';
const baseUrl = SITES_API;

export default function groupsApi(isConnected = false) {
  return {
    async fetchConsumptionCost(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchConsumptionCost',
        params
      });
    },
    async fetchGasConsumptionCost(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchGasConsumptionCost',
        params
      });
    },
    async fetchFull(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchFull',
        params
      });
    },
    async fetchElecMetersRanking(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: EFICIA_BASE_API,
        endpoint: 'fetchElecMetersRanking',
        params
      });
    },
    async fetch(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch',
        params
      });
    },
    async create(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'create',
        params
      });
    },
    async edit(groupId, params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    async delete(groupId, params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'delete',
        params
      });
    }
  };
}
