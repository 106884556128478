import { CHATBOT_BASE_API, USER_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'chatbot';
const baseUrl = CHATBOT_BASE_API;

export default function chatbotApi(isConnected = true) {
  return {
    async fetchAiToken(params) {
      return queryConfig({
        api,
        isConnected,
        // C'est le Backend de la Web App qui délivre le token
        baseUrl: USER_API,
        endpoint: 'aiToken',
        params
      });
    },
    async analyzeUserMessage(aiToken, params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'analyzeUserMessage',
        params,
        config: {
          headers: {
            Authorization: `Bearer ${aiToken}`
          }
        }
      });
    }
  };
}
