import { DATA_VISUALIZATION_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'dataVisualization';
const baseUrl = DATA_VISUALIZATION_API;

export default function dataVisualizationApi(isConnected = false) {
  return {
    async fetchVisibleAccesses(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchVisibleAccesses',
        params
      });
    },
    async fetchPersonalizedPageListByAccess(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchPersonalizedPageListByAccess',
        params
      });
    },
    async fetchPersonalizedPageWidgets(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchPersonalizedPageWidgets',
        params
      });
    },
    async fetchToken(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchToken',
        params
      });
    }
  };
}
