import { toCamelCase } from 'eficia/utilities/toCamelCase';
import { ALERTS_API, ELECTRICITY_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'alerts';
const baseUrl = ALERTS_API;

export default function alertsApi(isConnected = false) {
  return {
    async fetchAlertsHistory(params) {
      // return {
      //   data: {
      //     data: [
      //       {
      //         alertId: 'c52661b8-d1d0-4cd0-9c4a-2eb5c1a471be',
      //         alertName: 'My beautiful alert',
      //         siteId: 'c52661b8-d1d0-4cd0-9c4a-2eb5c1a471be',
      //         siteName: 'My beautiful site',
      //         lastDates: ['2001-01-01T00:00:00+00:00', '2001-01-06T23:59:59+00:00'],
      //         triggeredAlertsCount: 1,
      //         creatorEmail: 'john.doe@example.com'
      //       }
      //     ]
      //   },
      //   meta: {
      //     pagination: {
      //       currentPage: 1,
      //       perPage: 15,
      //       itemCount: 150,
      //       lastPage: 12
      //     }
      //   }
      // };
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchAlertsHistory',
        params
      });
    },
    async fetchAlertHistoryDetails(params) {
      // return {
      //   data: {
      //     data: {
      //       alertName: 'My beautiful alert',
      //       siteName: 'My beautiful site',
      //       type: 'consumption_deviation',
      //       electricityMeterNumbers: [12345678901234, 12345678901233],
      //       triggeredAlerts: [
      //         {
      //           dates: ['2001-01-01T00:00:00+00:00', '2001-01-06T23:59:59+00:00'],
      //           value: '50',
      //           electricityMeterNumber: '12345678901234',
      //           zoneName: 'Zone 1'
      //         }
      //       ]
      //     },
      //     meta: {
      //       pagination: {
      //         itemCount: 2,
      //         lastPage: 1,
      //         perPage: 15,
      //         currentPage: 1
      //       }
      //     }
      //   }
      // };
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchAlertHistoryDetails',
        params
      });
    },
    async fetchOrganization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOrganization',
        params
      });
    },
    async fetch(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch',
        params
      });
    },
    async fetchOne(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: toCamelCase(`fetchOne-${type}`),
        params
      });
    },
    async create(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: toCamelCase(`create-${type}`),
        params
      });
    },
    async edit(params, type) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: toCamelCase(`edit-${type}`),
        params
      });
    },
    async delete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'delete',
        params
      });
    },
    async subscribe(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: 'subscribe',
        params
      });
    },
    async unsubscribe(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'unsubscribe',
        params
      });
    },
    async massDelete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'massDelete',
        params
      });
    },
    async exportHistory(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: 'exportHistory',
        params
      });
    },
    async exportHistoryDetails(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl: ELECTRICITY_API,
        endpoint: 'exportHistoryDetails',
        params
      });
    }
  };
}
