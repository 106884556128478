import { createTheme } from '@mui/material/styles';
import typography from './typography';

const vars = {
  primary: '#224F7A',
  primaryVariant: {
    50: '#F0F9FF',
    100: '#E0F2FE',
    200: '#BAE6FD',
    300: '#7DD3FC',
    400: '#38BDF8',
    500: '#0EA5E9',
    600: '#0284C7',
    700: '#0369A1',
    800: '#075985',
    900: '#0C4A6E'
  },
  neutralVariant: {
    20: '#F8FAFC',
    50: '#F1F5F9',
    100: '#F0F2F4',
    200: '#E2E8F0',
    300: '#CBD5E1',
    400: '#94A3B8',
    500: '#64748B',
    600: '#475569',
    700: '#334155',
    800: '#1E293B',
    900: '#0F172A'
  },
  primaryInactive: '#2b6399',
  primaryLight: '#495368',
  primaryDark: '#09142a',
  secondary: '#3d4977', // #DFE5EA
  secondaryDark: '#3d4977',
  inheritDefault1: '#fefefe',
  inheritDefault2: '#f8f9ff',
  second: '#070919',
  indigo: '#7420ff',
  purple: '#793de6',
  pink: '#fc26a4',
  red: '#f83245',
  redInactive: '#fa7a86',
  orange: '#f4772e',
  yellow: '#ffc926',
  green: '#1bc943',
  teal: '#18e1a5',
  cyan: '#27dcf3',
  white: '#ffffff',
  black: '#000000',
  textAction: '#224F7A',
  textCaption: '#6E758E',
  borderDefault: '#DEDEDE'
};

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: vars.primary,
      inactive: vars.primaryInactive,
      50: vars.primaryVariant[50],
      100: vars.primaryVariant[100],
      200: vars.primaryVariant[200],
      300: vars.primaryVariant[300],
      400: vars.primaryVariant[400],
      500: vars.primaryVariant[500],
      600: vars.primaryVariant[600],
      700: vars.primaryVariant[700],
      800: vars.primaryVariant[800],
      900: vars.primaryVariant[900]
    },
    neutral: {
      20: vars.neutralVariant[20],
      50: vars.neutralVariant[50],
      100: vars.neutralVariant[100],
      200: vars.neutralVariant[200],
      300: vars.neutralVariant[300],
      400: vars.neutralVariant[400],
      500: vars.neutralVariant[500],
      600: vars.neutralVariant[600],
      700: vars.neutralVariant[700],
      800: vars.neutralVariant[800],
      900: vars.neutralVariant[900]
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    black: {
      main: vars.black
    },
    secondary: {
      main: vars.secondary,
      dark: vars.secondaryDark
    },
    error: {
      main: vars.red,
      inactive: vars.redInactive
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    textAction: vars.textAction,
    textCaption: vars.textCaption,
    borderDefault: vars.borderDefault
  },
  shape: {
    borderRadius: '0.5rem'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Cabin, Roboto, sans-serif',
          fontSize: '14px',
          fontWeight: 400
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '8px'
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          background: 'none',
          borderBottom: `1px solid ${vars.neutralVariant[300]}`
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: { variant: 'activable', color: 'inactive' },
          style: {
            padding: '3px 9px',
            border: `1px solid ${vars.neutralVariant[300]}`,
            backgroundColor: '#fff',
            color: vars.primary,
            '&:hover': {
              backgroundColor: vars.neutralVariant[100]
            }
          }
        },
        {
          props: { variant: 'activable', color: 'active' },
          style: {
            padding: '3px 9px',
            border: `1px solid ${vars.neutralVariant[300]}`,
            backgroundColor: vars.neutralVariant[200],
            color: vars.primary
          }
        }
      ],
      styleOverrides: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
          fontSize: '14px'
        },
        textSizeSmall: {
          padding: '4px 5px',
          fontSize: '14px'
        },
        label: {
          fontSize: '14px'
        },
        // contained:{
        //   color: vars.inheritDefault1,
        //   backgroundColor: vars.primaryLight,
        // },
        containedSizeSmall: {
          padding: '4px 16px'
        },
        root: ({ ownerState }) => ({
          textTransform: 'none',
          fontWeight: '500',
          minWidth: 'auto',
          fontSize: '14px',
          ...(ownerState.variant === 'outlined' && {
            border: `1px solid var(--border-action-hi)`,
            backgroundColor: '#fff',
            color: vars.primary,
            svg: {
              filter: 'var(--text-action-filter)'
            },
            '&:hover': {
              color: `${vars.primaryVariant[700]} !important`,
              border: `1px solid ${vars.primaryVariant[700]} !important`,
              svg: {
                filter: 'var(--text-action-light-filter) !important'
              }
            }
          })
        })
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${vars.neutralVariant[300]}`
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 800
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 400
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          color: vars.neutralVariant[400]
        },
        shrink: {
          color: vars.primary,
          fontSize: 16
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: vars.inheritDefault2
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 2
      }
    },
    MuiPickersYear: {
      styleOverrides: {
        yearSelected: {
          fontSize: '24px'
        }
      }
    },
    MuiPickersMonth: {
      styleOverrides: {
        monthSelected: {
          fontSize: '24px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: vars.second
        }
      }
    }
  },
  typography
});

export default MuiTheme;
