import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SparklesIcon from 'assets/eficia/icons/sparkles.svg';
import { ErrorBlock } from 'eficia/components/molecules/ErrorBlock';
import { BASE_SITE_PATH } from 'eficia/constants/paths';
import { Tabs, TabPanel } from 'eficia/components/molecules/Tabs';
import ErrorBoundary from 'eficia/views/error/ErrorBoundary';

import useFetchAiToken from '../services/chatbot/useFetchAiToken';
import useFetchSite from '../services/useFetchSite';
import useFetchSitesByGroups from '../services/useFetchSitesByGroups';
import { HelpButtonMenuNonEC } from './HelpButtonMenuNonEC';
import { HelpButtonMenuWithEC } from './HelpButtonMenuWithEC';
import { HelpButtonMenuChatbot } from './HelpButtonMenuChatbot';

export default function HelpButtonTabs({
  setIsLoaded,
  userSite,
  userGroups,
  userOperator,
  openFormContactModal,
  openCalendlyModal
}) {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const isSitePage = pathname.startsWith(BASE_SITE_PATH);

  const [currentTab, setCurrentTab] = useState(0);

  const { data: siteData, error: siteError } = useFetchSite(
    isSitePage ? userSite : null
  );

  const { data: sitesByGroupsData, error: sitesByGroupsError } =
    useFetchSitesByGroups(
      isSitePage
        ? null
        : {
            groupIds: userGroups,
            operator: userOperator
          }
    );

  const { data: aiTokenData, error: aiTokenError } = useFetchAiToken(
    isSitePage ? userSite : null
  );

  // Edge-case : Cas des organisations sans aucun site (organisation tout juste créer par exemple)
  const isOrganizationWithNoSites = isSitePage && !userSite;

  useEffect(() => {
    if (
      siteError ||
      sitesByGroupsError ||
      aiTokenError ||
      (siteData && aiTokenData) ||
      sitesByGroupsData ||
      isOrganizationWithNoSites
    ) {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteError,
    sitesByGroupsError,
    aiTokenError,
    siteData,
    aiTokenData,
    sitesByGroupsData
  ]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (siteError || sitesByGroupsError || aiTokenError) {
    return (
      <div data-testid="help-button-content" style={{ padding: 10 }}>
        <ErrorBlock />
      </div>
    );
  }

  let isHandledByEc = false;
  let currentCountry = null;
  let hasChatbot = false;

  if ((siteData && aiTokenData) || isOrganizationWithNoSites) {
    hasChatbot = !!aiTokenData?.data?.token;

    if (siteData?.data?.isHandledByEc && siteData?.data?.address?.countryId) {
      isHandledByEc = true;
      currentCountry = siteData.data.address.countryId;
    }
  } else if (sitesByGroupsData) {
    if (sitesByGroupsData?.data?.hasAtLeastOneSiteHandledByEc) {
      isHandledByEc = true;
    }
  } else {
    // Loading
    return null;
  }

  const tabs = [];

  if (hasChatbot) {
    tabs.push({
      icon: (
        <img
          src={SparklesIcon}
          alt={t('help_button.menu_chatbot.tabs.chatbot')}
        />
      ),
      title: t('help_button.menu_chatbot.tabs.chatbot'),
      component: (
        <HelpButtonMenuChatbot
          aiToken={aiTokenData?.data?.token}
          site={siteData?.data}
        />
      )
    });
  }

  if (!isHandledByEc) {
    tabs.push({
      icon: <FontAwesomeIcon icon={['fas', 'headset']} />,
      title: t('help_button.menu_chatbot.tabs.non_ec'),
      component: (
        <HelpButtonMenuNonEC openFormContactModal={openFormContactModal} />
      )
    });
  } else {
    tabs.push({
      icon: <FontAwesomeIcon icon={['fas', 'headset']} />,
      title: t('help_button.menu_chatbot.tabs.with_ec'),
      component: (
        <HelpButtonMenuWithEC
          currentCountry={currentCountry}
          openCalendlyModal={openCalendlyModal}
        />
      )
    });
  }

  if (tabs.length <= 1) {
    return <div data-testid="help-button-content">{tabs?.[0]?.component}</div>;
  }
  return (
    <div data-testid="help-button-content">
      <div style={{ paddingLeft: 16 }}>
        <Tabs
          tabs={tabs}
          color="inherit"
          currentTab={currentTab}
          onChange={handleChangeTab}
        />
      </div>
      <div
        style={{ borderTop: '1px solid var(--border-default)', paddingTop: 12 }}
      >
        {tabs.map((tab, index) => (
          <TabPanel value={currentTab} index={index} key={index}>
            <ErrorBoundary
              errorElement={
                <div style={{ padding: 10 }}>
                  <ErrorBlock />
                </div>
              }
            >
              {tab.component}
            </ErrorBoundary>
          </TabPanel>
        ))}
      </div>
    </div>
  );
}

HelpButtonTabs.propTypes = {
  setIsLoaded: PropTypes.func,
  userSite: PropTypes.string,
  userGroups: PropTypes.array,
  userOperator: PropTypes.string,
  openFormContactModal: PropTypes.func,
  openCalendlyModal: PropTypes.func
};
