import React, { Suspense, useEffect, forwardRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import { Notification } from 'eficia/components/molecules/Notification';
import { LayoutProvider } from 'eficia/contexts/LayoutProvider';
import { NotificationProvider } from 'eficia/contexts/NotificationProvider';
import { AnonymousTranslationProvider } from 'eficia/contexts/TranslationProvider';
import AppRoutes from 'eficia/routes/Routes';
import ErrorBoundary, { RootErrorPage } from 'eficia/views/error/ErrorBoundary';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import '../../../assets/base.scss';
import MuiTheme from '../../../theme';

// Il est important de charger les dépendances de Keycloakify uniquement :
// - Dans un contexte de Keycloak (https://auth.example.com)
// - Ou en dev (http://localhost), si `REACT_APP_MOCK_KEYCLOAK` est définie
// Ceci dans une objectif de réduire la taille du build et donc améliorer les performances
const KcThemeApp = lazyWithRetry(() => import('eficia/keycloak-theme'));

library.add(fas, far);

const forwardNotification = forwardRef((props, ref) => {
  return <Notification ref={ref} notification={props} />;
});

export default function App() {
  // init config react-query
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  // GTM
  function initGTM() {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_KEY
      // dataLayerName: 'PageDataLayer'
    });
  }

  // amCharts 5
  am5.addLicense('AM5C345010209');

  function initAxeptio() {
    const el = document.createElement('script');
    el.setAttribute('src', 'https://static.axept.io/sdk-slim.js');
    el.setAttribute('type', 'text/javascript');
    el.setAttribute('async', 'true');
    el.setAttribute('data-id', process.env.REACT_APP_AXEPTIO_ID);
    el.setAttribute('data-cookies-version', 'app eficia-fr');

    if (document.body !== null) {
      document.body.appendChild(el);
    }

    window._axcb = window._axcb || [];
    window._axcb.push((axeptio) => {
      axeptio.on('cookies:complete', (choices) => {
        if (choices.googletagmanager) {
          initGTM();
        }

        window.hideAxeptioButton();
      });
    });
  }

  useEffect(() => {
    if (process.env.REACT_APP_EFICIA_ENVIRONMENT !== 'production') {
      initGTM();
    } else {
      initAxeptio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary errorElement={<RootErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={MuiTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={MuiTheme}>
                  <CssBaseline />
                  <LayoutProvider>
                    <ErrorBoundary errorElement={<RootErrorPage />}>
                      <Suspense>
                        {(() => {
                          if (window.kcContext || process.env.REACT_APP_MOCK_KEYCLOAK) {
                            return (
                              <AnonymousTranslationProvider>
                                <SnackbarProvider
                                  Components={{
                                    custom: forwardNotification
                                  }}
                                  maxSnack={5}
                                  preventDuplicate
                                >
                                  <NotificationProvider>
                                    <KcThemeApp />
                                  </NotificationProvider>
                                </SnackbarProvider>
                              </AnonymousTranslationProvider>
                            );
                          }

                          return <AppRoutes />;
                        })()}
                      </Suspense>
                    </ErrorBoundary>
                  </LayoutProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
