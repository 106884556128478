import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import {
  SidebarHeaderDesktopWrapper,
  SidebarHeaderMobileWrapper
} from './SidebarHeader.style';

function SidebarHeader({ setSidebarToggleMobile, sidebarToggleMobile }) {
  const { t } = useTranslation();

  const Wrapper = sidebarToggleMobile
    ? SidebarHeaderMobileWrapper
    : SidebarHeaderDesktopWrapper;

  return (
    <Wrapper>
      <Tooltip title={t('sidebar.header.hide')} placement="right">
        <IconButton
          color="inherit"
          onClick={() => setSidebarToggleMobile(!sidebarToggleMobile)}
          size="large"
        >
          {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
        </IconButton>
      </Tooltip>
    </Wrapper>
  );
}

SidebarHeader.propTypes = {
  setSidebarToggleMobile: PropTypes.func,
  sidebarToggleMobile: PropTypes.bool
};

export default SidebarHeader;
