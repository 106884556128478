import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from 'eficia/views/app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay(), new BrowserTracing()],
  environment: process.env.REACT_APP_EFICIA_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'Non-Error exception captured', // Safe Link Outlook
    'Non-Error promise rejection captured' // Safe Link Outlook
  ]
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  maximumFileSizeToCacheInBytes: 5000000,
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          caches?.keys().then((names) => {
            names.forEach((name) => {
              caches?.delete(name);
            });
          });

          window.location.reload();
        }
      });
      if (waitingServiceWorker.postMessage)
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});
