import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import countryISO2 from 'country-iso-3-to-2';

import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchCountries() {
  const { t } = useTranslation();
  const client = eficiaClient();
  let countries = null;

  const fetcher = useCallback(() => {
    return client.users.fetchCountries();
  }, [client.users]);

  const { data, isFetching } = useQuery(['users-countries'], fetcher);

  if (data?.data.data) {
    // Afin d'afficher la liste des pays dans l'ordre, il est nécessaire de d'abord traduire
    // le pays dans la langue en cours (avec `t`) puis de trier les pays par ordre alphabétique
    // en prenant en compte le champ `displayOrder` qui met en avant certains pays plutot que d'autres
    // Un exemple : `NLD` qui est traduit `Pays-Bas` (en français) et `Netherlands` (en anglais)
    countries = data.data.data
      .map((country) => ({
        ...country,
        localizedName: t(`country.${country.id.toLowerCase()}.name`),
        displayOrder: country.displayOrder
      }))
      .sort((a, b) => {
        // Trie par displayOrder puis par localizedName
        const displayOrderA = a.displayOrder > 0 ? a.displayOrder : 999;
        const displayOrderB = b.displayOrder > 0 ? b.displayOrder : 999;
        if (displayOrderA > displayOrderB) {
          return 1;
        }
        if (displayOrderA < displayOrderB) {
          return -1;
        }
        return a.localizedName > b.localizedName ? 1 : -1;
      })
      .map((country) => ({
        ...country,
        // Ce service va retourner le alpha-2 (2 lettres) du pays pour afficher le drapeau du pays
        alpha2: countryISO2(country.id) || 'XX'
      }));
  }

  return {
    data: countries,
    isFetching
  };
}
