import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import PropTypes from 'prop-types';

export default function CountryFlag({ countryCode, size = 16, styles = {} }) {
  return (
    <ReactCountryFlag
      svg
      style={{
        borderRadius: 4,
        fontSize: size,
        width: 'auto',
        height: `${size}px`,
        ...styles
      }}
      countryCode={countryCode}
      data-testid={`country-flag-${countryCode}`}
    />
  );
}

CountryFlag.propTypes = {
  countryCode: PropTypes.string.isRequired,
  size: PropTypes.number,
  styles: PropTypes.object
};
