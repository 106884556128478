import PropTypes from 'prop-types';
import React from 'react';

import InputLoader from 'eficia/components/atoms/Loader/InputLoader';

import FormMultiSelect from '../forms/FormMultiSelect';

import useFetchGroups from './services/useFetchGroups';

export default function GroupsFormMultiSelect({
  formProps,
  validator,
  name,
  label,
  placeholder,
  ...rest
}) {
  const { data, isFetching } = useFetchGroups();

  return (
    <div>
      {isFetching ? (
        <InputLoader label={label} />
      ) : (
        <FormMultiSelect
          formProps={formProps}
          name={name}
          data={data || []}
          label={label}
          placeholder={placeholder}
          validator={validator}
          {...rest}
        />
      )}
    </div>
  );
}

GroupsFormMultiSelect.propTypes = {
  formProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  validator: PropTypes.object
};
