import React from 'react';
import PropTypes from 'prop-types';

import { Button as ButtonUi } from '@mui/material';

import { ButtonWrapper } from './Button.style';

/**
 * Button from Material-UI
 *
 * [Link to API for additional property](https://material-ui.com/api/button/)
 * */
export default function Button({
  color = 'primary',
  label,
  iconOnly = false,
  size = 'medium',
  fullWidth,
  variant = 'contained',
  ...rest
}) {
  return (
    <ButtonWrapper
      className={`${iconOnly && 'icon-only'} ${fullWidth && 'w-100'}`}
    >
      <ButtonUi
        disableRipple
        color={color}
        size={size}
        variant={variant}
        {...rest}
      >
        {iconOnly ? '' : label}
      </ButtonUi>
    </ButtonWrapper>
  );
}

Button.propTypes = {
  /** Color of button (can be override by className) */
  color: PropTypes.string,
  /** Element to display (end position) */
  endIcon: PropTypes.node,
  /** Text to display */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Show icon only */
  iconOnly: PropTypes.bool,
  /** Size of button */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Element to display (start position) */
  startIcon: PropTypes.node,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string
};
