import styled, { keyframes } from 'styled-components';

const animateFade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const animateExpand = keyframes`
  0% { max-height: 0; }
  100% { max-height: 1000px; }
`;

export const CountryMenuOpener = styled.a`
  background-color: white !important;
  transition: background-color 0.5s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }
`;

export const CountryMenuContainer = styled.div`
  animation-name: ${animateFade};
  animation-duration: 1s;
  animation-iteration-count: forwards;
`;

export const CountryMenuItem = styled.a`
  background-color: white !important;
  color: var(--text-body) !important;

  &.selected {
    background-color: #e0f2fe !important;
    color: var(--first) !important;
  }

  &:hover {
    background-color: #f0f9ff !important;
  }
`;

export const PhoneNumber = styled.p`
  animation-name: ${animateFade}, ${animateExpand};
  animation-duration: 2s;
  animation-iteration-count: forwards;
`;
