import styled from 'styled-components';

export const SidebarFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SidebarFooterSeparator = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.borderDefault};
`;
