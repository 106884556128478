import styled from 'styled-components';
import { TextField } from '@mui/material';

export const TextFieldUi = styled(TextField)`
  .MuiInputBase-input,
  .MuiFormLabel-root {
    font-size: 16px;
  }

  .MuiFormLabel-root {
    top: -1px;
  }

  &:hover fieldset {
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  .Mui-focused > div > .MuiFormLabel-asterisk {
    color: red;
  }
`;
