import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Copy from 'assets/eficia/icons/copy.svg';
import { Tooltip } from 'eficia/components/atoms/Tooltip';

import { ClipboardButtonUi } from './ClipboardButton.style';

export default function ClipboardButton({
  textToCopy,
  defaultTooltip = null,
  copiedTooltip = null,
  copiedDurationMs = 2000,
  size = 16,
  paddingSize = 12,
  children,
  ...rest
}) {
  const { t } = useTranslation();

  const [tooltipText, setTooltipText] = useState(
    defaultTooltip || t('copy_to_clipboard_button.default')
  );

  const handleClick = (e) => {
    e.preventDefault();

    window.navigator.clipboard.writeText(textToCopy);

    setTooltipText(copiedTooltip || t('copy_to_clipboard_button.copied'));

    // Reset tooltip text after a moment
    window.setTimeout(() => {
      setTooltipText(defaultTooltip || t('copy_to_clipboard_button.default'));
    }, copiedDurationMs);
  };

  if (children) {
    return (
      <ClipboardButtonUi
        href="#"
        onClick={handleClick}
        style={{ background: 'none' }}
        {...rest}
      >
        <Tooltip title={tooltipText} placement="top">
          {children}
        </Tooltip>
      </ClipboardButtonUi>
    );
  }

  return (
    <ClipboardButtonUi
      href="#"
      onClick={handleClick}
      style={{ width: size + paddingSize, height: size + paddingSize }}
      {...rest}
    >
      <Tooltip title={tooltipText} placement="top">
        <img src={Copy} width={size} height={size} alt={defaultTooltip} />
      </Tooltip>
    </ClipboardButtonUi>
  );
}

ClipboardButton.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  defaultTooltip: PropTypes.string,
  copiedTooltip: PropTypes.string,
  copiedDurationMs: PropTypes.number,
  size: PropTypes.number,
  paddingSize: PropTypes.number,
  children: PropTypes.any
};
