import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Radio, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Input from 'eficia/components/atoms/Input/Input';

import {
  OrganizationItem,
  OrganizationTitle,
  OrganizationName
} from './SidebarProfileMenuOrganizations.style';

const SEARCH_ORGANIZATION_NAME_THRESHOLD = 8;

export default function SidebarProfileMenuOrganizations({
  organizationId,
  organizations,
  changeOrganization
}) {
  const { t } = useTranslation();

  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState(organizationId);
  const [searchByOrganizationName, setSearchByOrganizationName] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  useEffect(() => {
    if (organizations?.length && organizationId) {
      // Afficher l'organisation courante en premier
      let newList = organizations
        .filter((organization) => organization.value === organizationId)
        .concat(
          organizations.filter(
            (organization) => organization.value !== organizationId
          )
        );

      // Filtrer par nom d'organisation
      if (searchByOrganizationName) {
        newList = newList.filter((organization) =>
          organization.label
            .toLowerCase()
            .includes(searchByOrganizationName.toLowerCase())
        );
      }

      setFilteredOrganizations(newList);
    }
  }, [organizations, organizationId, searchByOrganizationName]);

  const isSearchBarDisplayed =
    organizations?.length >= SEARCH_ORGANIZATION_NAME_THRESHOLD;

  const isSearchScrollDisplayed =
    isSearchBarDisplayed &&
    filteredOrganizations.length >= SEARCH_ORGANIZATION_NAME_THRESHOLD;

  return (
    !!organizations?.length && (
      <div data-testid="sidebar-profile-menu-organizations">
        <OrganizationTitle>
          {organizations?.length === 1
            ? t('sidebar.profile.organizations.title')
            : t('sidebar.profile.organizations.title.plural')}
        </OrganizationTitle>

        {isSearchBarDisplayed && (
          <div className="d-flex flex-wrap flex-row align-items-center">
            <Input
              placeholder={t(
                'sidebar.profile.organizations.search.placeholder'
              )}
              onChange={(event) => {
                setSearchByOrganizationName(event.target.value);
              }}
              style={{ margin: '0 12px 12px 12px' }}
            />
          </div>
        )}

        <div
          style={{
            alignItems: 'start',
            height: isSearchBarDisplayed
              ? 42 * SEARCH_ORGANIZATION_NAME_THRESHOLD
              : 'auto',
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollbarWidth: 'thin'
          }}
        >
          {filteredOrganizations.length === 0 && (
            <Typography
              className="font-italic text-dark"
              style={{ padding: '10px 16px 0 16px' }}
              date-testid="sidebar-profile-menu-organizations-no-result"
            >
              {t('sidebar.profile.organizations.search.no_result')}
            </Typography>
          )}

          {filteredOrganizations.map((organization) => {
            return (
              <OrganizationItem
                key={organization.value}
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  if (organization.value === organizationId) {
                    return;
                  }
                  setSelectedOrganizationId(organization.value);
                  changeOrganization(organization.value);
                }}
                className={
                  organization.value === organizationId ? 'selected' : ''
                }
                data-testid={`sidebar-profile-menu-organizations-${organization.value}`}
              >
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: '8px', color: '#1E293B' }}
                >
                  <FontAwesomeIcon icon={['fas', 'globe']} />
                  <OrganizationName title={organization.label}>
                    {organization.label}
                  </OrganizationName>
                </div>
                <Radio
                  color="primary"
                  checked={organization.value === selectedOrganizationId}
                  style={{
                    position: 'relative',
                    right: isSearchScrollDisplayed ? -22 : -14
                  }}
                />
              </OrganizationItem>
            );
          })}
        </div>
        <Divider style={{ marginTop: 12 }} />
      </div>
    )
  );
}

SidebarProfileMenuOrganizations.propTypes = {
  organizationId: PropTypes.string,
  organizations: PropTypes.array,
  changeOrganization: PropTypes.func
};
