import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EllipsisText from 'eficia/components/atoms/EllipsisText/EllipsisText';

import SidebarProfileMenu from './SidebarProfileMenu';
import {
  SidebarProfileDetails,
  SidebarButtonContainer
} from './SidebarProfileButton.style';

export default function SidebarProfileButton({ userData }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SidebarButtonContainer
        color="inherit"
        onClick={handleClick}
        style={{
          backgroundColor: anchorEl ? 'rgba(0, 0, 0, 0.03)' : undefined
        }}
        data-testid="sidebar-profile-button"
      >
        <Box>
          <Avatar
            alt={userData.email}
            style={{
              color: 'white',
              background: '#224F7A',
              width: 40,
              height: 40,
              position: 'relative',
              left: 1
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: 22, backgroundColor: '#224F7A' }}
              icon={['far', 'user']}
              className="text-default"
            />
          </Avatar>
        </Box>
        <SidebarProfileDetails>
          {userData.organizationName && (
            <strong>
              <EllipsisText text={userData.organizationName} maxLength={18} />
            </strong>
          )}
          <span>
            {t('sidebar.profile.settings')}{' '}
            <FontAwesomeIcon
              icon={['fas', 'chevron-up']}
              style={{ fontSize: 12 }}
            />
          </span>
        </SidebarProfileDetails>
      </SidebarButtonContainer>

      <SidebarProfileMenu
        userData={userData}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
}

SidebarProfileButton.propTypes = {
  userData: PropTypes.object
};
