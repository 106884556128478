import React, { useContext } from 'react';

import { UserContext } from 'eficia/contexts/UserProvider';

import SidebarProfileButton from './components/SidebarProfileButton';
import {
  SidebarFooterContainer,
  SidebarFooterSeparator
} from './SidebarFooter.style';

export default function SidebarFooter() {
  const { userData } = useContext(UserContext);

  return (
    <SidebarFooterContainer data-testid="sidebar-footer">
      <SidebarFooterSeparator />
      <SidebarProfileButton userData={userData} />
    </SidebarFooterContainer>
  );
}
