import { useCallback } from 'react';
import { useMutation } from 'react-query';

import chatbotClient from 'eficia/services/chatbotClient';

export default function useAnalyzeUserMessage(aiToken) {
  const client = chatbotClient();

  const fetcher = useCallback(
    (data) => {
      if (!aiToken || !data.query) {
        return false;
      }

      const params = {
        query: data.query
      };

      return client.chatbot.analyzeUserMessage(aiToken, params);
    },
    [client.chatbot, aiToken]
  );

  const mutation = useMutation(fetcher);

  return mutation.mutate;
}
