import React from 'react';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';

import { ChatbotMessage } from './ChatbotMessage';
import { DelayedLoader, FadedMessage } from './ChatbotHistory.style';

export function ChatbotHistory({ messages, onSubmit }) {
  const isWaitingBotResponse = messages[messages.length - 1]?.sender === 'user';

  return (
    <ul
      data-testid="chatbot-history"
      style={{
        padding: '0 2px 0 2px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '100%'
      }}
    >
      {messages.map((message, index) => (
        <FadedMessage key={index} data-testid="chatbot-history-message">
          <ChatbotMessage
            data={message.data}
            sender={message.sender}
            onSubmit={onSubmit}
          />
        </FadedMessage>
      ))}
      {isWaitingBotResponse && (
        <DelayedLoader
          key={`loader-${messages.length}`}
          data-testid="chatbot-history-loader"
        >
          <PulseLoader color="var(--text-caption)" size={8} />
        </DelayedLoader>
      )}
    </ul>
  );
}

ChatbotHistory.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.array.isRequired,
      sender: PropTypes.string.isRequired
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired
};
