import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export default function useFetchSite(siteId) {
  const client = eficiaClient();

  const fetcher = useCallback(async () => {
    if (!siteId) {
      return false;
    }

    const site = await client.sites.fetchOne(siteId);

    return site;
  }, [client.sites, siteId]);

  const { data, error, isFetching, refetch } = useQuery(
    ['site-help-button', siteId],
    fetcher
  );

  return {
    data: data?.data || null,
    error,
    isFetching,
    refetch
  };
}
