import { USER_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'users';
const baseUrl = USER_API;

export default function usersApi(isConnected = false) {
  return {
    async fetchRoles(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchRoles',
        params
      });
    },
    async fetchCountries(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchCountries',
        params
      });
    },
    async fetch(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetch',
        params
      });
    },
    async fetchByMeterIds(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchByMeterIds',
        params
      });
    },
    async create(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'create',
        params
      });
    },
    async addCountry(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'addCountry',
        params
      });
    },
    async editCountry(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'editCountry',
        params
      });
    },
    async edit(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'edit',
        params
      });
    },
    async delete(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'delete',
        params
      });
    },
    async transfer(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'transfer',
        params
      });
    }
  };
}
