import { HARMONY_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'harmony';
const baseUrl = HARMONY_API;

export default function harmonyApi(isConnected = false) {
  return {
    async fetchObjects(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchObjects',
        params
      });
    },
    async fetchObjectsTags(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchObjectsTags',
        params
      });
    }
  };
}
