import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

// Hook permettant de récupérer un tableau de `grdf` modifié au format attendu du composant MultiSelect
export default function useFetchGroups() {
  const client = eficiaClient();
  let formattedGroups = null;

  const fetcher = useCallback(() => {
    return client.groups.fetchFull();
  }, [client.groups]);

  const { data, isFetching } = useQuery(['groups-fetch-full'], fetcher);

  if (data?.data.data) {
    formattedGroups = data.data.data.map((data) => {
      return {
        label: data.name,
        value: data.id
      };
    });
  }

  return {
    data: formattedGroups,
    isFetching
  };
}
