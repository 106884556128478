import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from 'assets/eficia/icons/error.svg';
import { ErrorPage } from 'eficia/components/organisms/ErrorPage';
import { HELP_EMAIL_ADDRESS } from 'eficia/services/help';

// Ce composant est une page d'erreur **minimal** (pas de MUI, ni de thème et encore moins de traductions)
// Ce composant doit être utiliser comme page d'erreur au plus bas niveau de l'execution de l'application React
export function RootErrorPage() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--bg-contrast)'
      }}
      data-testid="root-error-page"
    >
      <div>
        <img src={ErrorIcon} width={24} height={24} alt="" />
      </div>
      <div
        style={{
          fontSize: 16,
          lineHeight: '20px',
          color: 'var(--text-body)',
          maxWidth: 317,
          margin: '0 16px 0 16px',
          textAlign: 'center'
        }}
      >
        {/*
          Important :  Veuillez laisser les traductions en dur ici (le contexte de localisation n'est pas encore chargé) 
        */}
        <div>Une erreur est survenue. Nous faisons notre possible pour corriger le problème.</div>
        <div>
          Veuillez réessayer plus tard ou nous contacter via le mail{' '}
          <a href={`mailto:${HELP_EMAIL_ADDRESS}`} style={{ color: 'var(--text-body)' }}>
            <u>{HELP_EMAIL_ADDRESS}</u>
          </a>{' '}
          si le problème persiste.
        </div>
      </div>
    </div>
  );
}

// Ce composant attrape les erreurs de rendu dans ses enfants et affiche un message d'erreur à la place
// En savoir plus : https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { errorElement = <ErrorPage />, children } = this.props;

    if (hasError) {
      return errorElement;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  errorElement: PropTypes.node,
  children: PropTypes.node
};
