import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Radio, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from 'eficia/components/atoms/Button';
import { ClipboardButton } from 'eficia/components/atoms/ClipboardButton';
import { CountryFlag } from 'eficia/components/atoms/CountryFlag';
import { Icon } from 'eficia/components/atoms/Icon';
import { UserContext } from 'eficia/contexts/UserProvider';
import { HELP_CENTER_URL, HELP_EC_BY_COUNTRY } from 'eficia/services/help';
import { handleExternalRedirection } from 'eficia/utilities/redirections';

import {
  CountryMenuContainer,
  CountryMenuItem,
  CountryMenuOpener,
  PhoneNumber
} from './HelpButtonMenuWithEC.style';

const VISIBLE_COUNTRY_HEIGHT = 40;
const MAX_VISIBLE_COUNTRIES = 5;

export function HelpButtonMenuWithEC({
  currentCountry = null,
  openCalendlyModal
}) {
  const { t } = useTranslation();

  const { userData } = useContext(UserContext);
  const { countries: organizationCountries } = userData;
  const [isCountryMenuOpen, setIsCountryMenuOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [energyCenter, setEnergyCenter] = useState();
  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);

  useEffect(() => {
    if (selectedCountry) {
      const energyCenter =
        HELP_EC_BY_COUNTRY.find(
          (ec) => ec.energyCenterCountryId === selectedCountry.id
        ) || HELP_EC_BY_COUNTRY[0];
      setEnergyCenter(energyCenter);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (organizationCountries?.length) {
      const country =
        organizationCountries.find(
          (country) => country.id === currentCountry
        ) || organizationCountries[0];
      setSelectedCountry(country);
    } else {
      // Impossible de récupérer les pays de l'organisation : fallback vers la France
      setSelectedCountry({
        id: 'FRA',
        alpha2: 'FR'
      });
    }
  }, [currentCountry, organizationCountries]);

  const handleToggleCountryMenu = (e) => {
    e.preventDefault();
    setIsCountryMenuOpen(!isCountryMenuOpen);
  };

  const handleChangeCountry = (e, country) => {
    e.preventDefault();
    setSelectedCountry(country);
    setIsCountryMenuOpen(false);
    setIsPhoneNumberVisible(false);
  };

  const handleTogglePhoneNumber = (e) => {
    e.preventDefault();
    setIsPhoneNumberVisible(!isPhoneNumberVisible);
  };

  const handleRedirectionToHelpCenter = (e) => {
    e.preventDefault();
    handleExternalRedirection(HELP_CENTER_URL);
  };

  if (!selectedCountry || !energyCenter) {
    return null;
  }

  return (
    <div data-testid="help-button-menu-with-ec">
      <div
        className="d-flex flex-column"
        data-testid={
          energyCenter
            ? `help-button-current-energy-center-${energyCenter.energyCenterCountryId}`
            : undefined
        }
      >
        <div
          className="d-flex flex-column"
          style={{ padding: '2px 16px 0 16px' }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-center"
            style={{ gap: 8, padding: '0 0 8px 0' }}
          >
            <div
              className="d-flex flex-row align-items-center"
              style={{ gap: 8 }}
            >
              <FontAwesomeIcon
                icon={['fas', 'headset']}
                style={{ fontSize: 20, color: 'var(--text-body)' }}
              />
              <Typography
                variant="h5"
                style={{ fontSize: 16, color: 'var(--text-body)' }}
              >
                {t('help_button.menu_with_ec.title')}
              </Typography>
            </div>

            <div style={{ position: 'relative', top: 0, right: -6 }}>
              <CountryMenuOpener
                href="#"
                onClick={handleToggleCountryMenu}
                style={{
                  padding: 8,
                  borderRadius: 4,
                  display: 'flex',
                  gap: 10,
                  pointerEvents:
                    organizationCountries?.length > 1 ? 'auto' : 'none'
                }}
                data-testid={`help-button-current-selected-country-${selectedCountry.id}`}
              >
                <CountryFlag size={16} countryCode={selectedCountry.alpha2} />

                {organizationCountries?.length > 1 && (
                  <FontAwesomeIcon
                    icon={[
                      'fas',
                      isCountryMenuOpen ? 'caret-up' : 'caret-down'
                    ]}
                    style={{ fontSize: 18, color: 'var(--text-caption)' }}
                  />
                )}
              </CountryMenuOpener>

              {isCountryMenuOpen && (
                <CountryMenuContainer
                  style={{
                    position: 'absolute',
                    bottom: 42,
                    right: 0,
                    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.08)',
                    borderRadius: 8,
                    overflowX: 'hidden',
                    overflowY:
                      organizationCountries?.length > MAX_VISIBLE_COUNTRIES
                        ? 'auto'
                        : 'hidden',
                    maxHeight: VISIBLE_COUNTRY_HEIGHT * MAX_VISIBLE_COUNTRIES,
                    width: 264
                  }}
                >
                  {organizationCountries?.map((country) => (
                    <CountryMenuItem
                      key={country.alpha2}
                      href="#"
                      onClick={(e) => handleChangeCountry(e, country)}
                      className={
                        selectedCountry.id === country.id ? 'selected' : ''
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: VISIBLE_COUNTRY_HEIGHT,
                        padding: '8px 16px 8px 16px',
                        position: 'relative'
                      }}
                      data-testid={`help-button-country-menu-item-${country.id}`}
                    >
                      <CountryFlag
                        size={16}
                        styles={{
                          marginRight: '10px'
                        }}
                        countryCode={country.alpha2}
                      />
                      {country.localizedName}
                      <Radio
                        color="primary"
                        checked={selectedCountry.id === country.id}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      />
                    </CountryMenuItem>
                  ))}
                </CountryMenuContainer>
              )}
            </div>
          </div>
          <div className="d-flex flex-column" style={{ gap: 16 }}>
            <div
              className="d-flex flex-row align-items-center"
              style={{ gap: 8 }}
            >
              <FontAwesomeIcon
                icon={['fas', 'at']}
                style={{ fontSize: 16, color: 'var(--text-body)' }}
              />
              <ClipboardButton
                textToCopy={energyCenter.energyCenterEmail}
                defaultTooltip={t('help_button.menu.copy_email')}
                data-testid={`help-button-email-link-${energyCenter.energyCenterCountryId}`}
              >
                <Typography style={{ fontSize: 14, color: 'var(--text-body)' }}>
                  {energyCenter.energyCenterEmail}
                </Typography>
              </ClipboardButton>

              <div style={{ marginLeft: -8 }}>
                <ClipboardButton
                  size={12}
                  textToCopy={energyCenter.energyCenterEmail}
                  defaultTooltip={t('help_button.menu.copy_email')}
                  data-testid={`help-button-email-copy-${energyCenter.energyCenterCountryId}`}
                />
              </div>
            </div>

            <a
              href="#"
              onClick={handleTogglePhoneNumber}
              className="d-flex flex-row align-items-center"
              style={{ position: 'relative', gap: 8 }}
              data-testid={
                !isPhoneNumberVisible
                  ? `help-button-expand-phone-number-${energyCenter.energyCenterCountryId}`
                  : undefined
              }
            >
              <FontAwesomeIcon
                icon={['fas', 'phone']}
                style={{ fontSize: 16, color: 'var(--text-action)' }}
              />
              <Typography style={{ fontSize: 14, color: 'var(--text-action)' }}>
                {t('help_button.menu_with_ec.contact_us')}
              </Typography>
              <span
                style={{
                  position: 'absolute',
                  top: -4,
                  right: -10,
                  padding: '5px 10px 5px 10px'
                }}
              >
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    isPhoneNumberVisible ? 'caret-up' : 'caret-down'
                  ]}
                  style={{ fontSize: 18, color: 'var(--text-action)' }}
                />
              </span>
            </a>

            {isPhoneNumberVisible && (
              <PhoneNumber
                style={{ fontSize: 14, color: 'var(--text-body)', margin: 0 }}
              >
                {energyCenter.energyCenterPhoneNumber}
              </PhoneNumber>
            )}
          </div>
          <div
            className="d-flex flex-column"
            style={{ paddingTop: 20, paddingBottom: 16 }}
          >
            <div style={{ position: 'relative' }}>
              <Divider />
              <Typography
                style={{
                  color: 'var(--text-caption)',
                  fontSize: '14px',
                  position: 'absolute',
                  top: '-11px',
                  left: 0,
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                <span
                  style={{ backgroundColor: 'white', padding: '0 8px 0 8px' }}
                >
                  {t('help_button.menu_with_ec.rdv_separator')}
                </span>
              </Typography>
            </div>

            <Typography
              variant="body1"
              style={{
                color: 'var(--text-caption)',
                fontSize: '14px',
                lineHeight: '18px',
                padding: '15px 0 12px 0'
              }}
            >
              {t('help_button.menu_with_ec.rdv_message')}
            </Typography>
            <Button
              variant="outlined"
              fullWidth
              className="btn-full-width"
              startIcon={<Icon name="calendar" />}
              label={t('help_button.menu_with_ec.rdv_button')}
              onClick={() =>
                openCalendlyModal(energyCenter.energyCenterCalendlyUrl)
              }
              data-testid={`help-button-rdv-${energyCenter.energyCenterCountryId}`}
            />
          </div>
        </div>

        <Divider />

        <a
          href="#"
          onClick={handleRedirectionToHelpCenter}
          className="d-flex flex-row align-items-center"
          style={{ gap: 8, padding: '14px 16px 6px 16px' }}
          data-testid="help-button-help-center"
        >
          <span style={{ borderRadius: 4, fontSize: 8, overflow: 'hidden' }}>
            <Icon name="eficia" size={16} />
          </span>
          <Typography
            variant="h5"
            style={{ fontSize: 14, color: 'var(--text-body)' }}
          >
            {t('help_button.menu_with_ec.help_center')}
          </Typography>
        </a>
      </div>
    </div>
  );
}

HelpButtonMenuWithEC.propTypes = {
  currentCountry: PropTypes.string,
  openCalendlyModal: PropTypes.func.isRequired
};
