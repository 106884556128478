import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Collapse } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { UserContext } from 'eficia/contexts/UserProvider';

import SidebarFilter from '../SidebarFilterBox/SidebarFilter';
import {
  AppSidebarButton,
  AppSidebarItem,
  AppSidebarItemSeparator,
  AppSidebarSubDepth,
  SidebarExpandIcon
} from './SidebarMenuListItem.style';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

function SidebarMenuListItem(props) {
  const {
    slug,
    title,
    href,
    depth,
    children,
    icon,
    isOpen: isOpenProp = false,
    filterTab,
    hasContent = false,
    setSidebarToggleMobile
  } = props;
  const [open, setOpen] = useState(isOpenProp);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { userFilters } = useContext(UserContext);

  useEffect(() => {
    setOpen(isOpenProp);
  }, [isOpenProp]);

  const listStyle = {
    marginTop: hasContent && depth === 2 ? 8 : 0,
    marginBottom: hasContent && depth === 2 ? 8 : 0
  };

  const buttonStyle = {
    marginTop: 4,
    marginBottom: 4,
    fontSize: depth === 0 ? 16 : 14,
    fontWeight: depth === 2 ? 400 : 600
  };

  function handleClick() {
    // si l'utilisateur clic sur un lien de la sidebar, on ferme le menu mobile (sauf si c'est sur suivi site ou suivi parc)
    if (!['sites', 'groups'].includes(slug)) {
      setSidebarToggleMobile(false);
    }

    if (pathname === href) {
      // si l'utilisateur clic sur le lien actuel dans la sidebar, on force le refresh de la page
      // cette maniere de faire pourra poser probleme dans le futur
      window.location.reload();
    }
  }

  if (hasContent) {
    return (
      <>
        <AppSidebarItem
          className={`depth-${depth}`}
          disableGutters
          style={listStyle}
        >
          <AppSidebarButton
            className={clsx({ active: open }, `depth-${depth}`)}
            onClick={handleClick}
            to={href}
            component={CustomRouterLink}
            style={buttonStyle}
          >
            {icon && icon}
            <span style={{ fontSize: buttonStyle.fontSize }}>{t(title)}</span>
            {open ? (
              <SidebarExpandIcon>
                <FontAwesomeIcon
                  icon={['fas', 'circle']}
                  className="expand-icon"
                  style={{ width: 4, height: 4, marginRight: 16 }}
                  hidden={depth === 1}
                />
              </SidebarExpandIcon>
            ) : (
              <SidebarExpandIcon>
                <ExpandLessIcon
                  hidden={!children}
                  className="expand-icon rotate"
                  color="inherit"
                />
              </SidebarExpandIcon>
            )}
          </AppSidebarButton>
          <Collapse in={open}>{children}</Collapse>
        </AppSidebarItem>
        {depth === 0 && <AppSidebarItemSeparator />}
      </>
    );
  }

  const button = (
    <AppSidebarButton
      disableRipple
      variant="text"
      className={clsx('leaf', { active: open }, `depth-${depth}`)}
      component={CustomRouterLink}
      style={buttonStyle}
      to={href}
      onClick={handleClick}
    >
      {icon && icon}
      {t(title)}
    </AppSidebarButton>
  );
  return (
    <>
      <AppSidebarItem disableGutters style={listStyle}>
        {filterTab !== undefined && (
          <div style={{ marginTop: 4, marginBottom: 0 }}>
            <SidebarFilter filterTab={filterTab} key={userFilters.siteId} />
          </div>
        )}

        {depth === 2 ? (
          <AppSidebarSubDepth>{button}</AppSidebarSubDepth>
        ) : (
          button
        )}
      </AppSidebarItem>
      {depth === 0 && <AppSidebarItemSeparator />}
    </>
  );
}

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number,
  href: PropTypes.string,
  slug: PropTypes.string,
  icon: PropTypes.node,
  isOpen: PropTypes.bool,
  filterTab: PropTypes.number,
  title: PropTypes.string.isRequired,
  hasContent: PropTypes.bool,
  setSidebarToggleMobile: PropTypes.func.isRequired
};

export default SidebarMenuListItem;
