import { lazy } from 'react';

// export const lazyWithRetry = (componentImport) =>
//   lazy(async () => {
//     try {
//       const component = await componentImport();

//       window.localStorage.setItem('page-has-been-force-refreshed', 'false');

//       return component;
//     } catch (error) {
//       if (typeof window.localStorage !== 'undefined') {
//         const pageHasAlreadyBeenForceRefreshed = JSON.parse(
//           window.localStorage.getItem('page-has-been-force-refreshed') ||
//             'false'
//         );

//         if (!pageHasAlreadyBeenForceRefreshed) {
//           // Assuming that the user is not on the latest version of the application.
//           // Let's refresh the page immediately.
//           window.localStorage.setItem('page-has-been-force-refreshed', 'true');
//           return window.location.reload();
//         }
//       }
//       // The page has already been reloaded
//       // Assuming that user is already using the latest version of the application.
//       // Let's let the application crash and raise the error.
//       throw error;
//     }
//   });

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyWithRetry = function (componentImport) {
  return lazy(
    () =>
      new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport()
          .then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
          })
          .catch((error) => {
            if (!hasRefreshed) {
              // not been refreshed yet
              window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
          });
      })
  );
};
