import PropTypes from 'prop-types';

import { measureSourceProps } from './meters';
import { zoneProps } from './zones';

// Enedis
export const enedisContractDetailsProps = PropTypes.shape({
  id: PropTypes.string,
  readingMode: PropTypes.string,
  segment: PropTypes.string,
  formulaPricingCode: PropTypes.string,
  historyDataAvailable: PropTypes.bool,
  dailyMeasureStatus: PropTypes.string,
  status: PropTypes.string,
  scheduledPowers: PropTypes.arrayOf(
    PropTypes.exact({
      scheduledPowers: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          code: PropTypes.string,
          startHour: PropTypes.string,
          endHour: PropTypes.string
        })
      ),
      scheduledShifts: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.string,
          code: PropTypes.string,
          value: PropTypes.string,
          unit: PropTypes.string
        })
      )
    })
  )
});
export const enedisElectricityMeterProps = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.string,
  zones: PropTypes.arrayOf(zoneProps),
  contract: enedisContractDetailsProps,
  measureSource: measureSourceProps,
  isRemoteReadable: PropTypes.bool
});

// Datadis
export const datadisContractDetailsProps = PropTypes.shape({
  id: PropTypes.string,
  cups: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  authorizedNif: PropTypes.string,
  distributorCode: PropTypes.number,
  accessFare: PropTypes.string,
  powerControlMode: PropTypes.string,
  subscribedPowers: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      contractId: PropTypes.string,
      power: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string
    })
  )
});
export const datadisElectricityMeterProps = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.string,
  zones: PropTypes.arrayOf(zoneProps),
  contract: datadisContractDetailsProps,
  isRemoteReadable: PropTypes.bool
});

// Base
export const electricityMeterProps = PropTypes.exact({
  id: PropTypes.string,
  number: PropTypes.string,
  zones: PropTypes.arrayOf(zoneProps),
  contract: PropTypes.object,
  measureSource: measureSourceProps,
  isRemoteReadable: PropTypes.bool,
  createdAt: PropTypes.string,
  hasData: PropTypes.bool,
  isUsable: PropTypes.bool
});
