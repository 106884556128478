import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PROFILE_EDIT_PATH, PROFILE_HOME_PATH } from 'eficia/constants/paths';
import { UserContext } from 'eficia/contexts/UserProvider';

const LocationContext = React.createContext();
const { Provider } = LocationContext;

function LocationProvider({ children }) {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [path, setPath] = useState(null);

  const { isUnknown, organizationNotFound } =
    useContext(UserContext);

  const contextValue = {
    setLocation: useCallback((location) => setPath(location), []),
    savedLocation: path
  };

  useEffect(() => {
    if ([PROFILE_HOME_PATH, PROFILE_EDIT_PATH].includes(pathname)) {
      // Ces écrans sont toujours disponibles
      return;
    }

    if (isUnknown) {
      if (pathname !== '/is-unknown') {
        navigate('/is-unknown');
      }
    } else if (organizationNotFound) {
      if (pathname !== '/organization-not-found') {
        navigate('/organization-not-found');
      }
    }
  }, [pathname, isUnknown, organizationNotFound, navigate]);

  return <Provider value={contextValue}>{children}</Provider>;
}

LocationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { LocationContext, LocationProvider };
