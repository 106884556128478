import { useCallback } from 'react';
import { useMutation } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

export function useEditLanguage() {
  const client = eficiaClient();

  const fetcher = useCallback(
    (data) => {
      const params = {
        lang: data.lang
      };

      return client.user.editLanguage(params);
    },
    [client.user]
  );

  const mutation = useMutation(fetcher);

  return mutation.mutate;
}
