import styled, { css } from 'styled-components';

export const IconContainer = styled.div`
  svg {
    width: 16px;
    height: 16px;
    fill: var(--tooltip-info-color);
  }
`;

export const WrapperInfo = styled.span`
  font-size: 0.8rem;
  position: ${({ position }) => position};
  ${({ placement }) =>
    placement === 'top' &&
    css`
      top: -16px;
      right: 0;
    `};

  ${({ placement }) =>
    placement === 'center' &&
    css`
      left: 0;
      bottom: 4px;
    `};

  ${({ placement }) =>
    placement === 'right' &&
    css`
      right: 0px;
      bottom: -1.5px;
    `};

  svg {
    ${({ theme }) => `color: ${theme.palette.primary.main}`}
  }
`;
