import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'eficia/components/atoms/Tooltip';
import Badge from 'eficia/components/atoms/Badge/Badge';

import { LabelTooltip } from './EllipsisText.style';

export default function EllipsisText({
  text,
  tooltipText: tooltipTextProps,
  maxLength = 35,
  showTooltip,
  badge,
  testId = 'ellipsisText'
}) {
  const tooltipText = tooltipTextProps || text;
  const textToDisplay = badge ? <Badge label={text} /> : text;
  const dotDisplay = text.length > maxLength ? <span>...</span> : '';
  return tooltipText.length > maxLength || showTooltip ? (
    <div data-testid={testId}>
      {badge ? (
        <Badge label={`${text.substring(0, maxLength)}...`} />
      ) : (
        <Tooltip title={tooltipText}>
          <LabelTooltip>
            {text.substring(0, maxLength)}
            {dotDisplay}
          </LabelTooltip>
        </Tooltip>
      )}
    </div>
  ) : (
    textToDisplay
  );
}

EllipsisText.propTypes = {
  testId: PropTypes.string,
  /** max length before ellipsis */
  maxLength: PropTypes.number,
  /** text to display */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** text in a badge */
  badge: PropTypes.bool,
  /** text of tooltip */
  tooltipText: PropTypes.string,
  /** show ellipsis */
  showTooltip: PropTypes.bool
};
