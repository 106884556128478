import axios from 'axios';

export default function translationsApi() {
  return {
    // anonymous
    async fetch({ locale }) {
      return axios.get(
        `${process.env.REACT_APP_S3_TRANSLATIONS_BUCKET_URL}/translations/webapp/${
          locale.split('-')[0]
        }.json?${Date.now()}`,
        { withCredentials: false }
      );
    }
  };
}
