import axiosConfig from './config/axiosConfig';
import { DATADIS_API } from './config/constants';

export default function datadisApi(isConnected = false) {
  return {
    async exportMetersToUpdateFile() {
      const query = await axiosConfig.post(
        `${DATADIS_API}/export-meters-to-update-file`,
        {},
        {
          responseType: 'blob'
        }
      );
      return query;
    },
    async uploadMetersToUpdateFile(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(
        `${DATADIS_API}/upload-meters-to-update-file`,
        fd,
        config
      );

      return query;
    }
  };
}
