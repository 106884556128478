import qs from 'qs';

// Convertie les paramètres de recherche (au format URL) en objet
export function parseQueryParams(search) {
  return qs.parse(search, { ignoreQueryPrefix: true });
}

// Convertie un objet en paramètres de recherche (au format URL)
// Retourne '' si l'objet est vide
export function stringifyQuery(query) {
  return qs.stringify(query, {
    addQueryPrefix: true,
    skipNulls: true,
    arrayFormat: 'brackets'
  });
}
