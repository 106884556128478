import React from 'react';
import PropTypes from 'prop-types';

import { CountryFlag } from 'eficia/components/atoms/CountryFlag';

import FormMultiSelect from '../forms/FormMultiSelect';

export default function CountriesFormMultiSelect({
  countries,
  formProps,
  name,
  label,
  placeholder,
  menuWidth,
  validator,
  ...props
}) {
  return (
    <FormMultiSelect
      key={countries.length}
      data={countries.map((country) => ({
        value: country.id,
        label: country.localizedName,
        icon: (
          <CountryFlag
            size={16}
            style={{
              marginRight: '10px'
            }}
            countryCode={country.alpha2}
          />
        )
      }))}
      formProps={formProps}
      name={name}
      label={label}
      placeholder={placeholder}
      menuWidth={menuWidth}
      validator={validator}
      {...props}
    />
  );
}

CountriesFormMultiSelect.propTypes = {
  countries: PropTypes.array,
  formProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  menuWidth: PropTypes.number,
  validator: PropTypes.object
};
