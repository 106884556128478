import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FormInput from 'eficia/views/forms/FormInput';

export function ChatbotInput({ onSubmit }) {
  const { t } = useTranslation();

  const formProps = useForm({
    defaultValues: {
      message: ''
    },
    shouldUnregister: false
  });
  const {
    control,
    handleSubmit: onUseFormSubmit,
    reset,
    formState: { errors }
  } = formProps;

  function handleSubmit(values) {
    onSubmit(values.message);
    reset();
  }

  return (
    <div data-testid="chatbot-input">
      <form
        onSubmit={onUseFormSubmit(handleSubmit)}
        style={{ position: 'relative' }}
      >
        {/* TODO: Improve the button over form input */}
        <FormInput
          control={control}
          adornment="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
          placeholder={t('chatbot.input.placeholder')}
          name="message"
          error={errors.message}
          maxLength={255}
          rules={{
            required: t('chatbot.input.required_msg')
          }}
          autoComplete="off"
        />
        <button
          type="submit"
          aria-label={t('chatbot.input.placeholder')}
          style={{
            position: 'absolute',
            right: 6,
            bottom: -3,
            fontSize: 16,
            background: 'none',
            border: 'none',
            padding: 10
          }}
          data-testid="chatbot-input-submit"
        >
          <FontAwesomeIcon
            icon={['far', 'paper-plane']}
            color="var(--text-action)"
          />
        </button>
      </form>
    </div>
  );
}

ChatbotInput.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
