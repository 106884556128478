import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { FormWrapper } from 'eficia/components/molecules/FormWrapper';
import Modal from 'eficia/components/organisms/Modal/Modal';
import useFormErrorNotification from 'eficia/hooks/useFormErrorNotification';
import { countryProps, organizationProps } from 'eficia/types/organization';
import FormInput from 'eficia/views/forms/FormInput';
import CountriesFormMultiSelect from 'eficia/views/lists/CountriesFormMultiSelect';

export default function OrganizationFormModal({
  organization = { countries: [] },
  onSubmit,
  availableCountries,
  ...rest
}) {
  const { t } = useTranslation();

  const formProps = useForm({
    defaultValues: {
      id: organization.organizationId || '',
      name: organization.organizationName || '',
      countryIds: organization.countries.map((country) => country.id) || []
    }
  });

  const {
    formState,
    formState: { errors },
    control,
    handleSubmit: onUseFormSubmit,
    watch
  } = formProps;
  const { isSubmitSuccessful } = formState;

  useFormErrorNotification(errors);

  function handleSubmit(values) {
    onSubmit(values);
  }

  return (
    <Modal
      onSubmit={onUseFormSubmit(handleSubmit)}
      data-testid={
        !organization.id
          ? 'create-organization-modal'
          : 'edit-organization-modal'
      }
      {...rest}
    >
      <Grid style={{ padding: 16 }} container spacing={2}>
        <Grid xs={12} item>
          <FormWrapper>
            <FormInput
              control={control}
              label={t('organization.create_modal.form.name')}
              name="name"
              error={errors.name}
              maxLength={64}
              rules={{
                required: t('organization.create_modal.form.name.error')
              }}
            />
          </FormWrapper>
        </Grid>
        {!organization.id && (
          <Grid xs={12} item>
            <FormWrapper>
              <CountriesFormMultiSelect
                countries={availableCountries}
                formProps={formProps}
                name="countryIds"
                label={t('country.form.country')}
                placeholder={t('country.form.country')}
                menuWidth={536}
                validator={{
                  validate: () => {
                    if (watch('countryIds').length === 0) {
                      return t('country.form.country.required_msg');
                    }
                    return true;
                  }
                }}
              />
            </FormWrapper>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}

OrganizationFormModal.propTypes = {
  organization: organizationProps,
  onSubmit: PropTypes.func.isRequired,
  availableCountries: PropTypes.arrayOf(countryProps)
};
