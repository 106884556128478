import PropTypes from 'prop-types';

export const zoneProps = PropTypes.exact({
  name: PropTypes.string,
  area: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  areas: PropTypes.array,
  id: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  siteId: PropTypes.string,
  fieldId: PropTypes.string
});

export const zoneListProps = PropTypes.shape({
  data: PropTypes.arrayOf(zoneProps)
});
