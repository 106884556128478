import translationsApi from './translations';
import userApi from './user';
import organizationApi from './organization';

export default function anonymousClient() {
  return {
    translations: translationsApi(),
    user: userApi(),
    organization: organizationApi()
  };
}
