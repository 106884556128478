import React from 'react';
import PropTypes from 'prop-types';

import { ClipLoader as Loader } from 'react-spinners';
import { Input } from 'eficia/components/atoms/Input';

function InputLoader({ label }) {
  return (
    <Input
      style={{
        marginTop: 5,
        marginBottom: 5,
        height: 40
      }}
      disabled
      label={label}
      adornment={<Loader size="15px" color="var(--primary)" loading />}
      data-testid="loader"
    />
  );
}

InputLoader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default InputLoader;
