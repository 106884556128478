// URL vers le centre d'aide d'Eficia
// NOTE: Un lien par langue ? ou le centre d'aide est-il multilingue ?
export const HELP_CENTER_URL = 'https://eficiahelp.zendesk.com/hc/fr';

// Liste des pays avec Energy Center dédié
export const HELP_EC_BY_COUNTRY = [
  {
    // Par défaut pour tous les pays
    energyCenterCountryId: 'FRA',
    energyCenterEmail: 'energycenter@eficia.com',
    energyCenterPhoneNumber: '+33 1 83 640 640',
    energyCenterCalendlyUrl:
      'https://calendly.com/rdv-energycenter-fr/30min?month=2023-11'
  },
  {
    // Spécifique à l'Espagne
    energyCenterCountryId: 'ESP',
    energyCenterEmail: 'energycenter.es@eficia.com',
    energyCenterPhoneNumber: '+34 912582509',
    energyCenterCalendlyUrl:
      'https://calendly.com/calendly-espana/eficia-reunion-de-30-minutos?month=2024-01'
  }
];

// Adresse email de contact
export const HELP_EMAIL_ADDRESS = 'support@appeficia.com';
