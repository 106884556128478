import moment from 'moment';

import { streamDownload } from 'eficia/utilities/streamDownload';

import { EFICIA_BASE_API, GRDF_API, HARMONY_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'measurements';

export default function measurementsApi(isConnected = false) {
  const createFakeData = ({ value = 'value', startDate }) => {
    const measures = [];
    const currentDate = moment(startDate);

    for (let i = 0; i < 90; i += 1) {
      measures.push({
        date: currentDate.valueOf(),
        [value]: Math.floor(Math.random() * 25)
      });
      currentDate.add(1, 'day');
    }

    return measures;
  };

  return {
    // news
    async fetchConsumptionPreview() {
      return queryConfig({
        api,
        isConnected,
        endpoint: 'fetchConsumptionPreview'
      });

      // Pour pouvoir simuler des données d'alerte
      // if (query.data?.data) {
      //   query.data?.data.forEach((d) => {
      //     d.hvalues = d.hvalues.map((h, index) => {
      //       if (d.date === '2023-02-25') {
      //         if ([1, 2, 4, 5, 7, 8, 10, 11].includes(index)) return 3;
      //         return 1;
      //       }
      //       if (d.date === '2023-02-24') {
      //         if ([1, 2, 4, 5, 7, 8, 10, 11].includes(index)) return 2;
      //         return 1;
      //       }
      //       return h;
      //     });
      //   });
      // }
    },
    // sites

    async fetchLastGasMonthsConsumption(params) {
      return queryConfig({
        api,
        baseUrl: GRDF_API,
        isConnected,
        endpoint: 'fetchLastGasMonthsConsumption',
        params
      });
    },
    async fetchGroupsLastMonthsConsumption(params) {
      return queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGroupsLastMonthsConsumption',
        params
      });
    },
    async fetchGroupsLastMonthsGasConsumption(params) {
      return queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGroupsLastMonthsGasConsumption',
        params
      });
    },
    async fetchLastMonthsConsumption(params) {
      return queryConfig({
        api,
        isConnected,
        endpoint: 'fetchLastMonthsConsumption',
        params
      });
    },
    async fetchSiteHeatMap(params) {
      return queryConfig({
        api,
        isConnected,
        endpoint: 'fetchSiteHeatMap',
        params
      });
    },
    async fetchGasSites(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGasSites',
        baseUrl: GRDF_API,
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchGasAggregateSites(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGasAggregateSites',
        baseUrl: GRDF_API,
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchGetGroupsGasConsumptionWidget(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGetGroupsGasConsumptionWidget',
        baseUrl: GRDF_API,
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchSites(params, type) {
      const query = await queryConfig({
        api,
        isConnected,
        // TODO: Deplacer tous les endpoints de `fetchEnedisSites` à `fetchSites` (nouveau domaine `electricity`)
        endpoint: type === 'consumption' ? 'fetchSites' : 'fetchEnedisSites',
        endpointSuffix: type,
        params
      });
      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchSite(params, type) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchSite',
        endpointSuffix: type,
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchSiteGas(params, aggregate) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: GRDF_API,
        endpoint: aggregate ? 'fetchSiteGasAggregate' : 'fetchSiteGas',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;
      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchHarmonyTemperature(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: HARMONY_API,
        endpoint: 'fetchHarmonyTemperature',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;
      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchHarmonyHumidity(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: HARMONY_API,
        endpoint: 'fetchHarmonyHumidity',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchHarmonyCo2(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: HARMONY_API,
        endpoint: 'fetchHarmonyCo2',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },

    async fetchSubscribedPower(measureSourceId, params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: `${EFICIA_BASE_API}/${measureSourceId}`,
        endpoint: 'fetchSubscribedPower',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },

    async fetchTemperature(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchTemperature',
        params
      });

      const data = [];

      // return query;
      if (query?.data?.data?.length > 0) {
        query.data.data.forEach((d) => {
          data.push({
            date: d.datetime,
            temperature: d.temperature
          });
        });
      }
      // const data = [];

      // // 168 retours (7jrs * 24h)
      // const currentDate = new Date(params.startDate);
      // currentDate.setHours(0);
      // for (let i = 0; i < 168; i += 1) {
      //   data.push({
      //     date: currentDate.getTime(),
      //     temperature: Math.floor(Math.random() * 25)
      //   });
      //   currentDate.setHours(currentDate.getHours() + 1);
      // }

      return {
        data: {
          data
        }
      };
    },
    async fetchConsumptionCost(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchConsumptionCost',
        params
      });

      return query;
    },
    async fetchGasConsumptionCost(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGasConsumptionCost',
        params
      });

      return query;
    },
    async fetchGlobalActivePowerGenerationList() {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGlobalActivePowerGenerationList'
      });

      return query;
    },
    async exportSites(params) {
      return streamDownload(
        `${EFICIA_BASE_API}/enedis/export-multi-sites-measures`,
        params
      );
    },
    async exportSite(params) {
      return streamDownload(
        `${EFICIA_BASE_API}/enedis/export-measures`,
        params
      );
    },
    async exportGasSite(params) {
      return streamDownload(
        `${GRDF_API}/export-gas-meter-consumption-measure-list`,
        params
      );
    },
    async exportGasAggregateSite(params) {
      return streamDownload(
        `${GRDF_API}/export-gas-meter-consumption-aggregated-measure-list`,
        params
      );
    },
    async exportGasSites(params) {
      // todo: to confirm
      return streamDownload(
        `${GRDF_API}/export-multisites-gas-meter-consumption-measures-list`,
        params
      );
    },
    async exportGasAggregateSites(params) {
      return streamDownload(
        `${GRDF_API}/export-gas-meter-multi-sites-aggregate-consumption-measure-list`,
        params
      );
    },
    async exportRanking(params) {
      return streamDownload(
        `${EFICIA_BASE_API}/enedis/export-consumption`,
        params
      );
    },
    async exportAirSite(params) {
      return streamDownload(
        `${HARMONY_API}/measure/export-objects-sensors`,
        params
      );
    },
    async exportWaterSite(params) {
      return streamDownload(
        `${EFICIA_BASE_API}/measure/export/water-consumption`,
        params
      );
    },
    async uploadSitesMeasuresFile(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchMultiSitesMesuresFile',
        params
      });

      return query;
    },
    async fetchGranularities(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchGranularities',
        params
      });

      return query;
    },
    async fetchSiteGranularities(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchSiteGranularities',
        params
      });

      return query;
    },
    async fetchWaterConsumption(params) {
      const query = await queryConfig({
        api,
        isConnected,
        baseUrl: HARMONY_API,
        endpoint: 'fetchWaterConsumption',
        params
      });

      const { data: dataQuery } = query;
      const { data, meta } = dataQuery;

      return {
        data: {
          data
        },
        nextId: meta?.nextRequest
      };
    },
    async fetchWaterConsumptionCost(params) {
      const query = await queryConfig({
        api,
        isConnected,
        endpoint: 'fetchWaterConsumptionCost',
        params
      });

      return query;
    }
  };
}
