import { useCallback } from 'react';
import { useQuery } from 'react-query';

import anonymousClient from 'eficia/services/anonymousClient';

export default function useFetchOrganizations() {
  const client = anonymousClient();
  let formattedOrganizations = [];

  const fetcher = useCallback(() => {
    return client.organization.fetch();
  }, [client.organization]);

  const { data, isFetching } = useQuery(['organizations-full-list'], fetcher);

  if (data?.data.data) {
    formattedOrganizations = data.data.data.map((data) => {
      return {
        label: data.name,
        value: data.id
      };
    });
  }

  return {
    data: formattedOrganizations,
    isFetching
  };
}
