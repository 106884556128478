import { Button, ButtonGroup } from '@mui/material';
import styled from 'styled-components';

export const ButtonGroupUi = styled(ButtonGroup)`
  .MuiButtonGroup-grouped {
    min-width: 30px;
  }
`;

export const ButtonUi = styled(Button)`
  background-color: white;
  padding: 3px 0px;
  height: 18px;
  border-radius: 4px;
  .MuiButton-label {
    font-size: 10px;
  }
  &:hover {
    background-color: white;
  }
`;
