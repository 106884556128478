import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader as Loader } from 'react-spinners';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';

import { Button } from 'eficia/components/atoms/Button';
import { TooltipInfo } from 'eficia/components/atoms/Tooltip';
import TooltipIcon from 'eficia/components/atoms/Tooltip/TooltipIcon';

import { ModalContent, ModalHeaderContent } from './styles/Modal.style';

export default function Modal({
  children,
  headerTitle,
  onSubmit,
  onClose,
  submitLabel,
  isModalOpen,
  tooltipText,
  isSubmitButtonDisabled,
  typeButton = 'button',
  classNameButton = '',
  showCancel = true,
  cancelLabel = 'modal.button.cancel',
  onCancel,
  headerButtons,
  withFooter = true,
  withHeader = true,
  maxWidth = 'sm',
  submitButton,
  helpTooltip,
  isLoading = false,
  isModalContentFullWidth = false,
  testId = 'modal',
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth={maxWidth}
      keepMounted
      PaperProps={{
        style: {
          overflow: 'visible'
        }
      }}
      open={isModalOpen}
      fullWidth
      data-testid={testId}
      {...rest}
    >
      {withHeader && (
        <>
          <ModalHeaderContent className="font-size-lg p-3 background-gray-light border-top-radius d-flex justify-content-between">
            {tooltipText && (
              <TooltipInfo
                className="mr-2"
                title={tooltipText}
                position="relative"
                placement="center"
              />
            )}
            <div
              className="d-flex flex-row w-100 align-items-center mb-1"
              style={{ gap: 4 }}
            >
              <Typography variant="h2">{headerTitle}</Typography>
              {helpTooltip && (
                <TooltipIcon
                  title={helpTooltip.title}
                  content={helpTooltip.content}
                  link={helpTooltip.link}
                />
              )}
            </div>

            {headerButtons && (
              <div className="d-flex">
                {headerButtons.map((button, index) => (
                  <Tooltip
                    key={index}
                    title={button.title}
                    hidden={!button.allowed}
                  >
                    <IconButton
                      onClick={button.onClick}
                      size="small"
                      data-testid={`${testId}-close`}
                    >
                      {button.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </div>
            )}
          </ModalHeaderContent>
          <Divider />
        </>
      )}
      <ModalContent
        className={`pt-4 pr-3 pl-3 pb-4 ${
          isModalContentFullWidth ? 'fullWidth' : ''
        }`}
      >
        {children}
      </ModalContent>
      {withFooter && (
        <>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            className="p-3 border-bottom-radius background-gray-light"
          >
            {showCancel && (
              <Button
                disabled={isLoading}
                className="mr-2"
                variant="outlined"
                label={t(cancelLabel)}
                onClick={onCancel || onClose}
                data-testid={`${testId}-cancel`}
              />
            )}
            {submitLabel && (
              <Button
                endIcon={
                  isLoading ? (
                    <Loader size="15px" color="var(--primary)" loading />
                  ) : undefined
                }
                disabled={isLoading || isSubmitButtonDisabled}
                className={classNameButton}
                label={submitLabel}
                onClick={onSubmit}
                type={typeButton}
                data-testid={`${testId}-submit`}
              />
            )}
            {submitButton}
          </Box>
        </>
      )}
    </Dialog>
  );
}

Modal.propTypes = {
  isLoading: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerButtons: PropTypes.array,
  isModalOpen: PropTypes.bool.isRequired,
  isSubmitButtonDisabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  tooltipText: PropTypes.string,
  typeButton: PropTypes.string,
  showCancel: PropTypes.bool,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  withFooter: PropTypes.bool,
  withHeader: PropTypes.bool,
  submitButton: PropTypes.object,
  classNameButton: PropTypes.string,
  helpTooltip: PropTypes.object,
  isModalContentFullWidth: PropTypes.bool,
  testId: PropTypes.string
};
