import { useCallback } from 'react';
import { useQuery } from 'react-query';

import anonymousClient from 'eficia/services/anonymousClient';

// Nous copions toutes les clés pas encore traduit dans la langue en cours **depuis la langue par défaut**
function copyTranslationsWithDefaultLocaleAsFallback(
  translations,
  defaultTranslations
) {
  // Ceci est un workaround au fait que `parseMissingKeyHandler` ne fonctionne pas avec les interpolations de variables
  // En effet, la valeur par défaut s'affiche avec des `{{variableName}}` mais non interpolées
  // Ticket : https://eficia.atlassian.net/browse/RF-27 / Bug: https://github.com/i18next/i18next/issues/1027

  if (!translations || !defaultTranslations) {
    return translations;
  }

  const copy = { ...translations };
  Object.keys(defaultTranslations).forEach((key) => {
    if (!copy[key]) {
      // Cette clef de traduction n'existe pas (ou est vide) dans la langue en cours
      // On la copie depuis la langue par défaut
      copy[key] = defaultTranslations[key];
    }
  });

  return copy;
}

export function useFetchTranslations({ defaultLocale, currentLocale }) {
  const client = anonymousClient();

  const fetcher = useCallback(() => {
    if (!defaultLocale || !currentLocale) {
      return null;
    }

    // Télécharger la langue par défaut (pour les fallbacks)
    const promises = [client.translations.fetch({ locale: defaultLocale })];
    if (currentLocale !== defaultLocale) {
      // Télécharger la langue courante
      promises.push(client.translations.fetch({ locale: currentLocale }));
    }

    // Tout en parallèle
    // Retourne un objet avec les traductions de la langue par défaut et de la langue courante
    return Promise.all(promises).then((results) => {
      const aggregated = {
        [defaultLocale]: results[0]?.data
      };
      if (results[1]) {
        aggregated[currentLocale] = copyTranslationsWithDefaultLocaleAsFallback(
          results[1]?.data,
          results[0]?.data
        );
      }
      return aggregated;
    });
  }, [client.translations, defaultLocale, currentLocale]);

  const { data, error, isFetching } = useQuery(
    ['translations', defaultLocale, currentLocale],
    fetcher,
    {
      cacheTime: 0
    }
  );

  return {
    data,
    error,
    isFetching
  };
}
