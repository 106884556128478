import { useState } from 'react';

export default function useMenu() {
  const [anchorElement, setAnchorElMenu] = useState(null);

  function open(event) {
    setAnchorElMenu(event.currentTarget);
  }

  function close() {
    setAnchorElMenu(null);
  }

  return {
    anchorElement,
    open,
    close
  };
}
