import { useCallback } from 'react';
import { useQuery } from 'react-query';

import eficiaClient from 'eficia/services/eficiaClient';

// Hook permettant de récupérer un tableau de `grdf` modifié au format attendu du composant MultiSelect
export default function useFetchSites() {
  const client = eficiaClient();
  let formattedSites = null;

  const fetcher = useCallback(() => {
    return client.sites.fetchFull();
  }, [client.sites]);

  const { data, isFetching } = useQuery(
    ['sites-single-select-fetch-full'],
    fetcher
  );

  if (data?.data.data) {
    formattedSites = data.data.data.map((data) => {
      return {
        label: data.name,
        value: data.id
      };
    });
  }

  return {
    data: formattedSites || [],
    isFetching
  };
}
