import React from 'react';
import PropTypes from 'prop-types';

import { BadgeContainer } from './Badge.style';

export default function Badge({ label, ...rest }) {
  return <BadgeContainer {...rest}>{label}</BadgeContainer>;
}

Badge.propTypes = {
  /** Text to display */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};
