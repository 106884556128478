import React from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import { Badge } from 'eficia/components/atoms/Badge';
import { Button } from 'eficia/components/atoms/Button';

import { MarkdownContainer, Message } from './ChatbotMessage.style';

export function ChatbotMessage({ data, sender, onSubmit }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      {data.map((line, index) => {
        let body = '';
        if (typeof line === 'string') {
          if (sender === 'bot') {
            // Bot is supporting Markdown
            body = (
              <MarkdownContainer data-testid="chatbot-message-text">
                <Markdown
                  rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}
                >
                  {line}
                </Markdown>
              </MarkdownContainer>
            );
          } else {
            // User
            body = <span data-testid="chatbot-message-text">{line}</span>;
          }
        } else if (line?.object) {
          if (line.object === 'badge') {
            body = (
              <Badge label={line.label} data-testid="chatbot-message-badge" />
            );
          } else if (line.object === 'button') {
            body = (
              <Button
                size="small"
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={['fas', line.icon]} />}
                label={line.label}
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => onSubmit(line.value || line.label)}
                data-testid="chatbot-message-button"
              />
            );
          }
        }
        if (!body) {
          return null;
        }

        return (
          <Message
            key={index}
            data-testid="chatbot-message"
            className={`sender-${sender}`}
          >
            {body}
          </Message>
        );
      })}
    </div>
  );
}

ChatbotMessage.propTypes = {
  data: PropTypes.array.isRequired,
  sender: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};
