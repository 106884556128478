import React from 'react';

import { withStyles } from '@mui/styles';
import { Tab, Tabs } from '@mui/material';

export const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textAlign: 'center',

    '&>div::before': {
      backgroundColor: '#224F7A',
      bottom: 0,
      content: '""',
      height: '2px',
      left: 0,
      right: 0,
      margin: 'auto',
      position: 'absolute'
    }
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    color: 'var(--text-action)',
    flexDirection: 'row',
    gap: 6,
    textTransform: 'none',
    fontSize: 16,
    lineHeight: '20px',
    marginRight: 8,
    minWidth: 0,
    padding: '15px 12px 15px 12px',
    '&:focus': {
      opacity: 1
    }
  }
}))((props) => <Tab disableRipple {...props} />);
