import PropTypes from 'prop-types';

export const metaProps = PropTypes.shape({
  pagination: PropTypes.exact({
    itemCount: PropTypes.number.isRequired,
    lastPage: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number
  })
});
