import styled from 'styled-components';

import { DialogContent } from '@mui/material';

export const ModalContent = styled(DialogContent)`
  overflow-y: auto;

  &.fullWidth {
    padding: 0 !important;
    overflow: hidden;
  }
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  height: 68px;
  align-items: center;

  span {
    bottom: 0;
  }
`;
