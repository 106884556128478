export async function streamDownload(url, params) {
  let response = {};

  const results = await fetch(url, {
    method: 'post', // HTTP POST to send query to server
    headers: {
      Accept: 'application/csv, text/plain, */*', // indicates which files we are able to understand
      'Content-Type': 'application/json' // indicates what the server actually sent
    },
    body: JSON.stringify(params), // server is expecting JSON
    credentials: 'include'
  })
    .then((res) => {
      response = res;
      const reader = res.body.getReader();

      return new ReadableStream({
        start(controller) {
          // The following function handles each data chunk
          function push() {
            // "done" is a Boolean and value a "Uint8Array"
            reader.read().then(({ done, value }) => {
              // If there is no more data to read
              if (done) {
                controller.close();
                return;
              }
              // Get the data and send it to the browser via the controller
              controller.enqueue(value);

              push();
            });
          }

          push();
        }
      });
    })
    .then((stream) => {
      // Respond with our stream
      return new Response(stream).text();
    })
    .then((result) => result)
    .catch((error) => {
      // catches error case and if fetch itself rejects
      error.response = {
        status: 0,
        statusText:
          'Cannot connect. Please make sure you are connected to internet.'
      };
      throw error;
    });

  return {
    headers: {
      'content-disposition': response.headers.get('content-disposition')
    },
    data: results
  };
}
