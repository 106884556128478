import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { NEWS_PATH, NEWS_DASHBOARD_PATH } from 'eficia/constants/paths';

const DashboardNewsHomePage = lazyWithRetry(() =>
  import('eficia/views/news/DashboardNewsHomePage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = NEWS_PATH;

  return path.replace(pathPrefix, '');
};

export default function NewsRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(NEWS_DASHBOARD_PATH)}
        element={<DashboardNewsHomePage />}
      />
    </Routes>
  );
}
