import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;

  .Button-underline {
    & > * {
      text-decoration: underline;
      text-underline-offset: 10px;
    }
  }

  .Button-skip {
    color: #a2a5a8;
    background: rgba(0, 0, 0, 0.03);
  }

  .btn-full-width {
    width: 100%;
  }

  .Button-success {
    background: #32bea6;
    width: 250px;
    height: 50px;

    & > * {
      font-size: 18px;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 2px;
    font-size: 14px !important;
  }
  .Button-action {
    svg {
      opacity: 0.6;
      width: 14px;
      height: 14px;
      font-size: 14px !important;
    }
  }
  .eficia-link-button:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }

  .MuiButton-containedPrimary {
    color: white;
  }

  .Button-secondary {
    background-color: #cbd3dd;
    color: ${({ theme }) => theme.palette.textAction};
  }

  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.grey[200]} !important;
    color: var(--text-disabled) !important;
    border: 1px solid transparent !important;

    svg {
      filter: var(--text-disabled-filter) !important;
    }
  }

  &.icon-only {
    .MuiButton-startIcon,
    .MuiButton-endIcon {
      margin: 0;
    }
  }
`;
