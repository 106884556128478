import React from 'react';
import PropTypes from 'prop-types';

import { tabProps } from 'eficia/types/tabs';

import { StyledTab, StyledTabs } from './Tabs.style';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export default function Tabs({
  color = 'inherit',
  tabs,
  currentTab,
  onChange,
  style,
  marginLeft = 16,
  icons = [],
  testId = 'tabs',
  ...rest
}) {
  return (
    <StyledTabs
      value={currentTab}
      textColor="inherit"
      onChange={onChange}
      variant="scrollable"
      TabIndicatorProps={{ children: <div />, style: { color: 'yellow' } }}
      data-testid={`${testId}-active-${currentTab}`}
      {...rest}
    >
      {tabs.map((tab, index) => (
        <StyledTab
          style={{ color, ...style }}
          key={index}
          label={
            tab.icon ? (
              <>
                {tab.icon} {tab.title}
              </>
            ) : (
              tab.title
            )
          }
          data-testid={tab.testId || `${testId}-tab-${index}`}
          {...a11yProps(index)}
        />
      ))}
    </StyledTabs>
  );
}
Tabs.propTypes = {
  style: PropTypes.object,
  indicatorStyle: PropTypes.string,
  color: PropTypes.string,
  tabs: PropTypes.arrayOf(tabProps).isRequired,
  currentTab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  marginLeft: PropTypes.number,
  icons: PropTypes.array,
  testId: PropTypes.string
};
