import styled from 'styled-components';

export const BadgeContainer = styled.div`
  display: inline-block;
  padding: 1px 4px 0 4px;
  border-radius: 4px;
  background-color: var(--bg-tertiary);
  color: var(--text-body);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 18px;

  svg {
    color: var(--text-body);

    &:hover {
      color: var(--text-action);
    }
  }
`;
