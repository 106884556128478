import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';

export default function MenuItem({ children, icon, ...rest }) {
  return (
    <ListItem button {...rest}>
      <div className="nav-link-icon opacity-6">{icon}</div>
      <span>{children}</span>
    </ListItem>
  );
}

MenuItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node
};
