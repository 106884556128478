import React from 'react';
import Icons from 'assets/eficia/icons/icons.svg';
import PropTypes from 'prop-types';

export default function Icon({ name, color, size, testId = 'icon' }) {
  return (
    <svg
      data-testid={testId}
      className={`icon icon-${name}`}
      stroke={color}
      fill={color}
      width={size}
      height={size}
    >
      <use xlinkHref={`${Icons}#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
  testId: PropTypes.string
};
