import React from 'react';
import { Route, Routes } from 'react-router-dom';

import EquipmentsIcon from 'assets/eficia/icons/equipments.svg';
import {
  AIR_CURVE_GROUPS_HOME_PATH,
  BASE_GROUPS_PATH,
  DASHBOARD_GROUP_HOME_PATH,
  DEPRECATED_CURVE_GROUPS_HOME_PATH,
  DEPRECATED_ELECTRICITY_METERS_GROUPS_RANKING_PATH,
  ELECTRICITY_ACCOUNTING_GROUPS_HOME_PATH,
  ELECTRICITY_CURVE_GROUPS_HOME_PATH,
  GAS_ACCOUNTING_GROUPS_HOME_PATH,
  GAS_CURVE_GROUPS_HOME_PATH,
  HVAC_CURVE_GROUPS_HOME_PATH,
  MISCELLANEOUS_GROUPS_HOME_PATH,
  OTHER_EQUIPMENTS_GROUPS_HOME_PATH,
  WATER_ACCOUNTING_GROUPS_HOME_PATH,
  WATER_CURVE_GROUPS_HOME_PATH
} from 'eficia/constants/paths';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

const DashboardGroupHomePage = lazyWithRetry(() =>
  import('eficia/views/groups/DashboardGroupsHomePage')
);
const GroupsElectricityCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/groups/GroupsElectricityCurveHomePage')
);
const GroupsGasCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/groups/GroupsGasCurveHomePage')
);
const DataVizGroupsPage = lazyWithRetry(() =>
  import('eficia/views/dataviz/DataVizGroupsPage')
);
const RedirectPage = lazyWithRetry(() =>
  import('eficia/views/error/RedirectPage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = BASE_GROUPS_PATH;

  return path.replace(pathPrefix, '');
};

export default function GroupsRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(DASHBOARD_GROUP_HOME_PATH)}
        element={<DashboardGroupHomePage />}
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_CURVE_GROUPS_HOME_PATH)}
        element={<GroupsElectricityCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(GAS_CURVE_GROUPS_HOME_PATH)}
        element={<GroupsGasCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(WATER_CURVE_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.consumption.water"
            icon="chart-simple"
            title="groups.view.water_curve.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(AIR_CURVE_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.air"
            icon="temperature-half"
            title="groups.view.air_curve.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(HVAC_CURVE_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.equipments.hvac"
            icon={<img src={EquipmentsIcon} alt="" />}
            title="groups.view.equipments_hvac.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(OTHER_EQUIPMENTS_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.equipments.other"
            icon={<img src={EquipmentsIcon} alt="" />}
            title="groups.view.equipments_others.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_ACCOUNTING_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.accounting.electricity"
            icon="file-lines"
            title="groups.view.accounting_electricity.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(GAS_ACCOUNTING_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.accounting.gas"
            icon="file-lines"
            title="groups.view.accounting_gas.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(WATER_ACCOUNTING_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.accounting.water"
            icon="file-lines"
            title="groups.view.accounting_water.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(MISCELLANEOUS_GROUPS_HOME_PATH)}
        element={
          <DataVizGroupsPage
            access="groups.miscellaneous"
            icon="ellipsis"
            title="groups.view.miscellaneous.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_CURVE_GROUPS_HOME_PATH)}
        element={
          <RedirectPage
            to={({ searchObj, stringifyQuery }) => {
              if (searchObj?.currentTab === 'gas') {
                return `${GAS_CURVE_GROUPS_HOME_PATH}${stringifyQuery({
                  ...searchObj,
                  currentTab: undefined
                })}`;
              }
              return `${ELECTRICITY_CURVE_GROUPS_HOME_PATH}${stringifyQuery({
                ...searchObj,
                currentTab: undefined
              })}`;
            }}
          />
        }
      />
      <Route
        path={removeRoutePrefix(
          DEPRECATED_ELECTRICITY_METERS_GROUPS_RANKING_PATH
        )}
        element={
          <RedirectPage
            to={({ searchObj, stringifyQuery }) => {
              return `${ELECTRICITY_CURVE_GROUPS_HOME_PATH}${stringifyQuery({
                ...searchObj,
                currentTab: 'ranking'
              })}`;
            }}
          />
        }
      />
    </Routes>
  );
}
