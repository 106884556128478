import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { LOGIN_PATH } from 'eficia/constants/paths';
import eficiaClient from 'eficia/services/eficiaClient';

export default function useLogout() {
  const client = eficiaClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetcher = useCallback(() => {
    return client.user.logout();
  }, [client.user]);

  const mutation = useMutation(fetcher, {
    onSuccess: (response) => {
      const { data } = response;
      queryClient.clear();
      if (data?.data?.redirect_url) {
        window.location.replace(data?.data?.redirect_url);
      } else {
        navigate(LOGIN_PATH);
      }
    },
    onError: () => {}
  });
  return mutation.mutate;
}
