// Nettoyer une URL en évitant les doubles slash
// Exemple de retour : Si url='https://example.com/a//b/' alors 'https://example.com/a/b/'
export const removeMultipleSlashes = (url) => {
  const parts = url.split('://');

  return `${parts[0]}://${parts[1].split('//').join('/')}`;
};

// Obtenir l'URL de base de la Web App Eficia
// Exemple de retour : 'https://example.com'
export const getEficiaBaseUrl = () => {
  // Par défaut, il s'agit de la valeur présente dans le fichier .env
  let baseUrl = process.env.REACT_APP_EFICIA_BASE_URL;

  if (window.kcContext?.client?.baseUrl) {
    // Sauf lorsqu'on est dans un théme Keycloak
    // Auquel cas on utilise la valeur de l'objet kcContext
    baseUrl = window.kcContext.client.baseUrl;
  }

  return removeMultipleSlashes(baseUrl);
};

// Obtenir le referer nécessaire pour les URLs de retour
// Exemple de retour : Si subdomain='test' alors 'https://test.example.com/'
export const getRefererFromSubdomain = (subdomain) => {
  const parts = getEficiaBaseUrl().split('://');

  return removeMultipleSlashes(`${parts[0]}://${subdomain}.${parts[1]}/`);
};

// Obtenir l'URL de l'API de la Web App Eficia
// Exemple de retour : 'https://example.com/api'
export const getEficiaApiUrl = () => {
  // Par défaut, il s'agit de la valeur présente dans le fichier .env
  let baseUrl = process.env.REACT_APP_EFICIA_API_URL;

  if (window.kcContext?.client?.baseUrl?.includes?.('app.')) {
    // Sauf lorsqu'on est dans un théme Keycloak
    // Auquel cas on utilise la valeur de l'objet kcContext qu'on transforme légèrement
    // Exemple de transformation : https://app.example.com devient https://ws.example.com
    baseUrl = window.kcContext.client.baseUrl.replace('app.', 'ws.');
  }

  return removeMultipleSlashes(`${baseUrl}/api`);
};
