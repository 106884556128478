import React from 'react';
import { Route, Routes } from 'react-router-dom';

import EquipmentsIcon from 'assets/eficia/icons/equipments.svg';
import {
  AIR_CURVE_SITE_HOME_PATH,
  BASE_SITE_PATH,
  BAS_ACCESS_SITE_HOME_PATH,
  DASHBOARD_SITE_HOME_PATH,
  DEPRECATED_CURVE_SITE_HOME_PATH,
  DEPRECATED_HARMONY_CURVE_SITE_HOME_PATH,
  DEPRECATED_HARMONY_PILOT_SITE_HOME_PATH,
  ELECTRICITY_ACCOUNTING_SITE_HOME_PATH,
  ELECTRICITY_CURVE_SITE_HOME_PATH,
  GAS_ACCOUNTING_SITE_HOME_PATH,
  GAS_CURVE_SITE_HOME_PATH,
  HVAC_CURVE_SITE_HOME_PATH,
  MISCELLANEOUS_SITE_HOME_PATH,
  OTHER_EQUIPMENTS_SITE_HOME_PATH,
  WATER_ACCOUNTING_SITE_HOME_PATH,
  WATER_CURVE_SITE_HOME_PATH
} from 'eficia/constants/paths';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

const DashboardSiteHomePage = lazyWithRetry(() =>
  import('eficia/views/site/DashboardSiteHomePage')
);
const ElectricityCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/site/ElectricityCurveHomePage')
);
const GasCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/site/GasCurveHomePage')
);
const AirCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/site/AirCurveHomePage')
);
const WaterCurveHomePage = lazyWithRetry(() =>
  import('eficia/views/site/WaterCurveHomePage')
);
const HarmonyPilotHomePage = lazyWithRetry(() =>
  import('eficia/views/site/HarmonyPilotHomePage')
);
const DataVizSitePage = lazyWithRetry(() =>
  import('eficia/views/dataviz/DataVizSitePage')
);
const RedirectPage = lazyWithRetry(() =>
  import('eficia/views/error/RedirectPage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = BASE_SITE_PATH;

  return path.replace(pathPrefix, '');
};

export default function SiteRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(DASHBOARD_SITE_HOME_PATH)}
        element={<DashboardSiteHomePage />}
      />
      <Route
        path={removeRoutePrefix(BAS_ACCESS_SITE_HOME_PATH)}
        element={<HarmonyPilotHomePage />}
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_CURVE_SITE_HOME_PATH)}
        element={<ElectricityCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(GAS_CURVE_SITE_HOME_PATH)}
        element={<GasCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(WATER_CURVE_SITE_HOME_PATH)}
        element={<WaterCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(AIR_CURVE_SITE_HOME_PATH)}
        element={<AirCurveHomePage />}
      />
      <Route
        path={removeRoutePrefix(HVAC_CURVE_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.equipments.hvac"
            icon={<img src={EquipmentsIcon} alt="" />}
            title="site.view.equipments_hvac.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(OTHER_EQUIPMENTS_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.equipments.other"
            icon={<img src={EquipmentsIcon} alt="" />}
            title="site.view.equipments_others.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.accounting.electricity"
            icon="file-lines"
            title="site.view.accounting_electricity.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(GAS_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.accounting.gas"
            icon="file-lines"
            title="site.view.accounting_gas.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(WATER_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.accounting.water"
            icon="file-lines"
            title="site.view.accounting_water.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(MISCELLANEOUS_SITE_HOME_PATH)}
        element={
          <DataVizSitePage
            access="sites.miscellaneous"
            icon="ellipsis"
            title="site.view.miscellaneous.title"
          />
        }
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_CURVE_SITE_HOME_PATH)}
        element={
          <RedirectPage
            to={({ searchObj, stringifyQuery }) => {
              if (searchObj?.currentTab === 'gas') {
                return `${GAS_CURVE_SITE_HOME_PATH}${stringifyQuery({
                  ...searchObj,
                  currentTab: undefined
                })}`;
              }
              return `${ELECTRICITY_CURVE_SITE_HOME_PATH}${stringifyQuery({
                ...searchObj,
                currentTab: undefined
              })}`;
            }}
          />
        }
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_HARMONY_CURVE_SITE_HOME_PATH)}
        element={<RedirectPage to={() => AIR_CURVE_SITE_HOME_PATH} />}
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_HARMONY_PILOT_SITE_HOME_PATH)}
        element={<RedirectPage to={() => BAS_ACCESS_SITE_HOME_PATH} />}
      />
    </Routes>
  );
}
