import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LayoutContext = React.createContext();
const { Provider } = LayoutContext;

function LayoutProvider({ children }) {
  const [isSidebarPresent, setIsSidebarPresent] = useState(false);
  const [isPageTitlePresent, setIsPageTitlePresent] = useState(false);
  const [isHeaderContainingTabs, setIsHeaderContainingTabs] = useState(false);
  const [isFixedActionsFooterPresent, setIsFixedActionsFooterPresent] =
    useState(false);
  const [isEmptyViewPresent, setIsEmptyViewPresent] = useState(false);

  return (
    <Provider
      value={{
        isSidebarPresent,
        setIsSidebarPresent,
        isPageTitlePresent,
        setIsPageTitlePresent,
        isHeaderContainingTabs,
        setIsHeaderContainingTabs,
        isFixedActionsFooterPresent,
        setIsFixedActionsFooterPresent,
        isEmptyViewPresent,
        setIsEmptyViewPresent
      }}
    >
      {children}
    </Provider>
  );
}

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { LayoutProvider, LayoutContext };
