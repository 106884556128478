import styled from 'styled-components';

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
`;

export const AppMain = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

`;

export const AppContentInner = styled.div`
  padding: 1rem 1rem (1rem / 2);
  display: flex;
  flex: 1;
`;

export const AppContentWrapper = styled.div`
  display: block;
  position: relative;
  padding: 0 24px;
`;
