import { Link } from '@mui/material';
import styled from 'styled-components';

export const ActionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 14px;
  margin-bottom: 8px;
`;

export const ActionItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 8px 16px 8px 16px;

  span {
    color: #1e293b;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;
