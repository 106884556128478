import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'eficia/components/atoms/Input';
import { errorProps } from 'eficia/types/react-hook-form/reactHookForm';

export default function FormInput({
  control,
  label,
  error,
  rules,
  name,
  onChange,
  defaultValue,
  maxLength,
  removeSpaces,
  ...rest
}) {
  let labelToDisplay = label;
  if (rules && rules.required) {
    if (typeof labelToDisplay === 'string') {
      labelToDisplay = `${labelToDisplay}*`;
    }
  }

  return (
    <Controller
      render={({
        field: { value, ref, onChange: onChangeController, ...field }
      }) => (
        <Input
          label={labelToDisplay}
          error={error}
          inputRef={ref}
          value={value}
          onChange={(e) => {
            if (removeSpaces) {
              e.target.value = e.target.value.replace(/\s+/g, '');
            }
            if (maxLength) {
              if (e.target.value.length > maxLength) {
                e.target.value = e.target.value.substring(0, maxLength);
              }
            }
            onChangeController(e);
            if (onChange) onChange(e);
          }}
          {...rest}
          {...field}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
}

FormInput.propTypes = {
  onChange: PropTypes.func,
  control: PropTypes.object,
  error: errorProps,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  removeSpaces: PropTypes.bool
};
