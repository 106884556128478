import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClipboardButton } from 'eficia/components/atoms/ClipboardButton';
import { HELP_EMAIL_ADDRESS } from 'eficia/services/help';

export default function ErrorBlock() {
  const { t } = useTranslation();

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center"
      style={{ maxWidth: 317, margin: '0 16px 0 16px', gap: 16 }}
      data-testid="error-block"
    >
      <div style={{ fontSize: 16 }}>
        <FontAwesomeIcon
          icon={['fas', 'triangle-exclamation']}
          color="var(--text-body)"
          size="xl"
        />
      </div>
      <div
        style={{
          fontSize: 16,
          lineHeight: '20px',
          color: 'var(--text-body)'
        }}
      >
        {/* 
            Important : 
            Veuillez laisser les traductions en dur ici (elles sont en fallback),
            Elles seront utilisées quand une erreur survient avant d'avoir chargé les traductions
          */}
        <div>
          {t('error.page.general.line1') ||
            'Une erreur est survenue. Nous faisons notre possible pour corriger le problème.'}
        </div>
        <div>
          {t('error.page.general.line2.begin') ||
            'Veuillez réessayer plus tard ou nous contacter via le mail'}{' '}
          <ClipboardButton
            textToCopy={HELP_EMAIL_ADDRESS}
            defaultTooltip={
              t('error.page.copy_email') || "Copier l'adresse mail"
            }
            copiedTooltip={
              t('error.page.copied_email') || '✅ Adresse mail copiée'
            }
            data-testid="error-block-email-link"
            style={{
              display: 'inline',
              color: 'var(--text-body)',
              backgroundColor: 'transparent'
            }}
          >
            <u>{HELP_EMAIL_ADDRESS}</u>
          </ClipboardButton>{' '}
          {t('error.page.general.line2.end') || 'si le problème persiste.'}
        </div>
      </div>
    </div>
  );
}
