import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import InputLoader from 'eficia/components/atoms/Loader/InputLoader';
import {
  AIR_CURVE_SITE_HOME_PATH,
  DASHBOARD_SITE_HOME_PATH,
  ELECTRICITY_CURVE_SITE_HOME_PATH,
  GAS_CURVE_SITE_HOME_PATH
} from 'eficia/constants/paths';
import { parseQueryParams } from 'eficia/utilities/query';

import FormMultiSelect from '../forms/FormMultiSelect';
import useFetchSites from './services/useFetchSites';

export default function SitesFormSingleSelect({
  label,
  placeholder,
  formProps,
  name,
  autoSelect = false,
  ...rest
}) {
  const { search, pathname } = useLocation();

  const { data: sites, isFetching } = useFetchSites();
  const { setValue, watch } = formProps;

  const siteIds = watch('siteId');
  const searchObj = parseQueryParams(search);
  const { siteId } = searchObj;

  useEffect(() => {
    if (!isFetching) {
      if (autoSelect) {
        if (
          pathname === DASHBOARD_SITE_HOME_PATH ||
          pathname === ELECTRICITY_CURVE_SITE_HOME_PATH ||
          pathname === AIR_CURVE_SITE_HOME_PATH ||
          pathname === GAS_CURVE_SITE_HOME_PATH
        ) {
          if (siteIds.length === 0) {
            if (siteId) {
              if (siteIds[0] !== siteId) {
                setValue('siteId', siteId);
              }
            } else if (sites[0]) {
              setValue('siteId', sites[0].value);
            }
          }
        }
      }
    }
  }, [isFetching, siteId, siteIds, autoSelect, sites, pathname, setValue]);

  return (
    <div>
      {isFetching ? (
        <InputLoader label={label} />
      ) : (
        <FormMultiSelect
          key={`${sites.length}-${siteIds[0]}`}
          formProps={formProps}
          name={name}
          data={sites}
          label={label}
          placeholder={placeholder}
          {...rest}
        />
      )}
    </div>
  );
}

SitesFormSingleSelect.propTypes = {
  autoSelect: PropTypes.bool,
  formProps: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,

  name: PropTypes.string.isRequired
};
