/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox as CheckboxMui, Typography } from '@mui/material';

import { Icon } from 'eficia/components/atoms/Icon';

import TreeNode from '../tree-node';
import TreeSubHeader from '../tree-node/NodeSubHeader';
import { findIndex } from '../utils';

const shouldRenderNode = (node, searchModeOn, data) => {
  if (searchModeOn || node.expanded) return true;

  const parent = node._parent && data.get(node._parent);
  // if it has a parent, then check parent's state.
  // otherwise root nodes are always rendered
  return !parent || parent.expanded;
};

// Workaround afin d'avoir le hook de traduction dans un composant de classe
function Translation({ translationKey }) {
  const { t } = useTranslation();

  return <>{t(translationKey)}</>;
}
Translation.propTypes = {
  translationKey: PropTypes.string.isRequired
};

class Tree extends Component {
  constructor(props) {
    super(props);
    const { pageSize } = this.props;
    this.currentPage = 1;
    this.computeInstanceProps(props, true);
    this.state = {
      items: this.allVisibleNodes.slice(0, pageSize)
    };
  }

  componentDidMount() {
    this.setState({ scrollableTarget: this.node.parentNode });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeDescendant } = nextProps;
    const { pageSize, activeDescendant: activeDescendantProps } = this.props;
    const hasSameActiveDescendant = activeDescendant === activeDescendantProps;
    this.computeInstanceProps(nextProps, !hasSameActiveDescendant);
    this.setState({
      items: this.allVisibleNodes.slice(0, this.currentPage * pageSize)
    });
  }

  computeInstanceProps = (props, checkActiveDescendant) => {
    const { pageSize } = this.props;
    this.allVisibleNodes = this.getNodes(props);
    this.totalPages = Math.ceil(this.allVisibleNodes.length / pageSize);
    if (checkActiveDescendant && props.activeDescendant) {
      const currentId = props.activeDescendant.replace(/_li$/, '');
      const focusIndex =
        findIndex(this.allVisibleNodes, (n) => n.key === currentId) + 1;
      this.currentPage = focusIndex > 0 ? Math.ceil(focusIndex / pageSize) : 1;
    }
  };

  getNodes = () => {
    const {
      data,
      keepTreeOnSearch,
      keepChildrenOnSearch,
      searchModeOn,
      mode,
      readOnly,
      onAction,
      onChange,
      onCheckboxChange,
      onNodeToggle,
      activeDescendant,
      clientId,
      dataTestId
    } = this.props;
    const items = [];
    data.forEach((node) => {
      if (shouldRenderNode(node, searchModeOn, data)) {
        items.push(
          node.isSubHeader ? (
            <TreeSubHeader
              key={node._id}
              dataTestId={dataTestId}
              {...node}
             />
          ) : (
            <TreeNode
              keepTreeOnSearch={keepTreeOnSearch}
              keepChildrenOnSearch={keepChildrenOnSearch}
              key={node._id}
              dataTestId={dataTestId}
              {...node}
              searchModeOn={searchModeOn}
              onChange={onChange}
              onCheckboxChange={onCheckboxChange}
              onNodeToggle={onNodeToggle}
              onAction={onAction}
              mode={mode}
              readOnly={readOnly}
              clientId={clientId}
              activeDescendant={activeDescendant}
            />
          )
        );
      }
    });
    return items;
  };

  hasMore = () => this.currentPage < this.totalPages;

  loadMore = () => {
    const { pageSize } = this.props;
    this.currentPage += 1;
    const nextItems = this.allVisibleNodes.slice(
      0,
      this.currentPage * pageSize
    );
    this.setState({ items: nextItems });
  };

  setNodeRef = (node) => {
    this.node = node;
  };

  getAriaAttributes = () => {
    const { mode } = this.props;

    const attributes = {
      /* https://www.w3.org/TR/wai-aria-1.1/#select
       * https://www.w3.org/TR/wai-aria-1.1/#tree */
      role: mode === 'simpleSelect' ? 'listbox' : 'tree',
      'aria-multiselectable': /multiSelect|hierarchical/.test(mode)
    };

    return attributes;
  };

  render() {
    const {
      withSelectAll = true,
      searchModeOn,
      onSelectAllClick,
      selectAllChecked,
      mode,
      dataTestId
    } = this.props;
    const { scrollableTarget, items } = this.state;

    return (
      <ul
        className={`root ${searchModeOn ? 'searchModeOn' : ''}`}
        ref={this.setNodeRef}
        {...this.getAriaAttributes()}
      >
        {scrollableTarget && (
          <InfiniteScroll
            height={this.hasMore() ? 250 : 'auto'}
            style={{ maxHeight: 250 }}
            dataLength={items.length}
            next={this.loadMore}
            hasMore={this.hasMore()}
            loader={
              <span className="searchLoader">
                <Translation translationKey="multiselect.loading" />
              </span>
            }
            scrollableTarget={scrollableTarget}
          >
            {mode !== 'simpleSelect' && withSelectAll && (
              <label htmlFor="selectAll" className="selectAllWrapper">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ gap: 4 }}
                >
                  <Icon size={22} name="task-list" />
                  <Typography variant="h2">
                    <Translation translationKey="multiselect.select_all" />
                  </Typography>
                </div>
                <CheckboxMui
                  size="small"
                  disableRipple
                  sx={{
                    '&:hover': { backgroundColor: 'transparent' }
                  }}
                  checked={selectAllChecked}
                  type="checkbox"
                  id="selectAll"
                  name="selectAll"
                  onChange={onSelectAllClick}
                  data-testid={dataTestId ? `${dataTestId}-select-all` : undefined}
                />
              </label>
            )}
            {items}
          </InfiniteScroll>
        )}
      </ul>
    );
  }
}

Tree.propTypes = {
  data: PropTypes.object,
  keepTreeOnSearch: PropTypes.bool,
  keepChildrenOnSearch: PropTypes.bool,
  searchModeOn: PropTypes.bool,
  onChange: PropTypes.func,
  onNodeToggle: PropTypes.func,
  onAction: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect']),
  pageSize: PropTypes.number,
  readOnly: PropTypes.bool,
  clientId: PropTypes.string,
  activeDescendant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // TODO: Résoudre le type en node
  dropdownNode: PropTypes.object,
  dataTestId: PropTypes.string,
  onSelectAllClick: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  withSelectAll: PropTypes.bool
};

Tree.defaultProps = {
  pageSize: 100
};

export default Tree;
