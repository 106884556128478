export const AND_OPERATOR = 'and';
export const OR_OPERATOR = 'or';

export const operators = {
  [AND_OPERATOR]: {
    label: 'multiselect.operators.and',
    value: 'and'
  },
  [OR_OPERATOR]: {
    label: 'multiselect.operators.or',
    value: 'or'
  }
};
