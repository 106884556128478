import { useCallback } from 'react';
import { useQuery } from 'react-query';

import chatbotClient from 'eficia/services/chatbotClient';

export default function useFetchAiToken(siteId) {
  const client = chatbotClient();

  const fetcher = useCallback(async () => {
    if (!siteId) {
      return false;
    }

    try {
      return await client.chatbot.fetchAiToken({ siteId });
    } catch (error) {
      const { response } = error;
      if (
        response?.status === 401 &&
        response?.data?.errors?.includes('site_not_found')
      ) {
        // Le site n'a pas smart cvc : chatbot non disponible
        return { data: { data: { token: null } } };
      }
      return Promise.reject(error);
    }
  }, [client.chatbot, siteId]);

  const { data, error, isFetching, refetch } = useQuery(
    ['ai-token-help-button', siteId],
    fetcher
  );

  return {
    data: data?.data || null,
    error,
    isFetching,
    refetch
  };
}
