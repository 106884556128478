import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import {
  ALERTS_HISTORY_DETAILS_PATH,
  ALERTS_HOME_PATH,
  ALERTS_CREATE_PATH,
  ALERTS_EDIT_PATH,
  ALERTS_COPY_PATH,
  DEPRECATED_ALERTS_MANAGEMENT_PATH
} from 'eficia/constants/paths';

const AlertsHomePage = lazyWithRetry(() =>
  import('eficia/views/alerts/AlertsHomePage')
);
const AlertsCreatePage = lazyWithRetry(() =>
  import('eficia/views/alerts/AlertsCreatePage')
);
const AlertHistoryDetailsPage = lazyWithRetry(() =>
  import('eficia/views/alerts/AlertHistoryDetailsPage')
);
const RedirectPage = lazyWithRetry(() =>
  import('eficia/views/error/RedirectPage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = ALERTS_HOME_PATH;

  return path.replace(pathPrefix, '');
};

export default function AlertsRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(ALERTS_HOME_PATH)}
        element={<AlertsHomePage />}
      />
      <Route
        path={removeRoutePrefix(ALERTS_CREATE_PATH)}
        element={<AlertsCreatePage />}
      />
      <Route
        path={removeRoutePrefix(ALERTS_EDIT_PATH)}
        element={<AlertsCreatePage mode="edit" />}
      />
      <Route
        path={removeRoutePrefix(ALERTS_COPY_PATH)}
        element={<AlertsCreatePage mode="copy" />}
      />
      <Route
        path={removeRoutePrefix(ALERTS_HISTORY_DETAILS_PATH)}
        element={<AlertHistoryDetailsPage />}
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_ALERTS_MANAGEMENT_PATH)}
        element={
          <RedirectPage
            to={({ searchObj, stringifyQuery }) => {
              return `${ALERTS_HOME_PATH}${stringifyQuery({
                ...searchObj
              })}`;
            }}
          />
        }
      />
    </Routes>
  );
}
