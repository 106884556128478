import styled from 'styled-components';

export const ClipboardButtonUi = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.2s;

  :hover {
    color: ${({ theme }) => theme.palette.black.main};
    background-color: #f8f8f8;
  }

  :active {
    color: ${({ theme }) => theme.palette.black.main};
    opacity: 0.4;
  }
`;
