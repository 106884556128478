// src/common/hooks/useAPIError/index.js
import { useContext } from 'react';
import { NotificationContext } from 'eficia/contexts/NotificationProvider';

// Hook pour gérer les notifications
export default function useNotification() {
  const { notification, addNotification, removeNotification } =
    useContext(NotificationContext);
  return { notification, addNotification, removeNotification };
}
