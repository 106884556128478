import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { Fab, Menu } from '@mui/material';

import QuestionWhite from 'assets/eficia/icons/question-white.svg';
import { Icon } from 'eficia/components/atoms/Icon';
import { useMenu } from 'eficia/components/molecules/Menu';
import { CalendlyModal } from 'eficia/components/organisms/Calendly';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import { UserContext } from 'eficia/contexts/UserProvider';
import ContactFormModal from 'eficia/views/modal/ContactFormModal';
import HelpButtonTabs from 'eficia/views/template/HelpButton/components/HelpButtonTabs';

export default function HelpButton() {
  const [isFormContactModalOpen, setIsFormContactModalOpen] = useState(false);
  const [calendlyUrl, setCalendlyUrl] = useState();
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  const { t } = useTranslation();

  const { userOperator, userGroups, userSite } = useContext(UserContext);

  const { isFixedActionsFooterPresent } = useContext(LayoutContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const actionMenu = useMenu();

  const onClose = () => {
    actionMenu.close();
  };

  const openFormContactModal = () => {
    onClose();
    setIsFormContactModalOpen(true);
  };

  const openCalendlyModal = (url) => {
    onClose();
    setCalendlyUrl(url);
    setIsCalendlyModalOpen(true);
  };

  if (isFixedActionsFooterPresent) {
    return null;
  }

  return (
    <>
      <div
        data-testid="help-button"
        style={{
          position: 'fixed',
          zIndex: 900,
          bottom: 32,
          right: 32,
          width: 48,
          height: 48
        }}
      >
        <Fab
          onClick={(e) => {
            setIsLoaded(false);
            actionMenu.open(e);
          }}
          style={{
            boxShadow: 'none',
            backgroundColor:
              actionMenu.anchorElement && isLoaded
                ? 'var(--text-caption)'
                : 'var(--text-action)',
            width: 48,
            height: 48
          }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {actionMenu.anchorElement ? (
            !isLoaded ? (
              <ClipLoader color="white" loading size={28} />
            ) : (
              <Icon size={20} name="close" />
            )
          ) : (
            <img src={QuestionWhite} width="32" height="32" alt="" />
          )}
        </Fab>
      </div>
      {actionMenu.anchorElement && (
        <Menu
          sx={{
            '& .MuiPaper-root': {
              /* `overflow` permet d'avoir le menu des langues du Help Button en dehors de celui-ci */
              overflow: 'visible',
              /* `top` et `bottom` permettent d'avoir le contenu du Help Button toujours tout en bas, */
              /* même quand on affiche le numero de téléphone, ce qui agrandi le contenu du Help Button */
              top: 'auto !important',
              bottom: 90,
              opacity: 0,
              visibility: 'hidden',
              '&:has([data-testid="help-button-content"])': {
                visibility: 'visible'
              }
            }
          }}
          disableScrollLock
          anchorEl={actionMenu.anchorElement}
          keepMounted
          open={Boolean(actionMenu.anchorElement)}
          anchorOrigin={{
            vertical: -8,
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={onClose}
        >
          <div style={{ width: 295 }}>
            <HelpButtonTabs
              setIsLoaded={setIsLoaded}
              userSite={userSite}
              userGroups={userGroups}
              userOperator={userOperator}
              openFormContactModal={openFormContactModal}
              openCalendlyModal={openCalendlyModal}
            />
          </div>
        </Menu>
      )}
      {isCalendlyModalOpen && calendlyUrl && (
        <CalendlyModal
          calendlyUrl={calendlyUrl}
          onClose={() => {
            setIsCalendlyModalOpen(false);
          }}
        />
      )}
      {isFormContactModalOpen && (
        <ContactFormModal
          headerTitle={t('contact_form_modal.title')}
          submitLabel={t('contact_form_modal.submit')}
          typeButton="submit"
          isModalOpen={isFormContactModalOpen}
          onClose={() => setIsFormContactModalOpen(false)}
        />
      )}
    </>
  );
}
