import React from 'react';
import PropTypes from 'prop-types';
import { siteProps } from 'eficia/types/sites';

import { Chatbot } from './chatbot/Chatbot';

export function HelpButtonMenuChatbot({ aiToken, site }) {
  return (
    <div
      data-testid="help-button-menu-chatbot"
      className="d-flex flex-column"
      style={{
        gap: 18,
        padding: '4px 16px 8px 16px',
        width: '100%',
        height: '294px'
      }}
    >
      <Chatbot aiToken={aiToken} site={site} />
    </div>
  );
}

HelpButtonMenuChatbot.propTypes = {
  aiToken: PropTypes.string.isRequired,
  site: siteProps.isRequired
};
