import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';

export default function TooltipText({
  label,
  title,
  content,
  linkLabel = 'tooltip.see_more',
  linkTarget = '_blank',
  link,
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <div style={{ fontWeight: 'normal' }}>
          <div style={{ fontWeight: '500', fontSize: 16 }}>{title}</div>
          <div style={{ marginTop: 8 }}>{content}</div>
          <div style={{ marginTop: 8 }}>
            <a
              style={{ color: 'lightgrey', textDecoration: 'underline' }}
              target={linkTarget}
              href={link}
            >
              {t(linkLabel)}
            </a>
          </div>
        </div>
      }
      placement="right"
      interactive
      leaveDelay={0}
      enterTouchDelay={0}
      leaveTouchDelay={1500}
      {...rest}
    >
      <span>{label}</span>
    </Tooltip>
  );
}

TooltipText.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  linkTarget: PropTypes.string
};
