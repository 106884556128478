import { useCallback } from 'react';
import { useMutation } from 'react-query';

import anonymousClient from 'eficia/services/anonymousClient';

export default function useGetPermissions() {
  const client = anonymousClient();

  const fetcher = useCallback(
    (data) => {
      const params = {
        permissions: data.permissions
      };

      if (!params.permissions.includes('add-site')) {
        params.permissions.push('add-site');
      }

      return client.user.getPermissions(params);
    },
    [client.user]
  );

  const mutation = useMutation(fetcher, {
    onSuccess: async (response) => {},
    onError: () => {}
  });

  return mutation;
}
