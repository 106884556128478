import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

import useNotification from 'eficia/hooks/useNotification';
import useEficiaClient from 'eficia/services/eficiaClient';

export default function useContact() {
  const client = useEficiaClient();
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  const fetcher = useCallback(
    (data) => {
      const params = {
        description: data.message,
        object: data.object,
        userEmail: data.email
      };

      return client.user.contact(params);
    },
    [client.user]
  );

  const mutation = useMutation(fetcher, {
    onSuccess: () => {
      addNotification({
        message: t('contact_form_modal.success'),
        type: 'success'
      });
    },
    onError: () => {
      addNotification({
        message: t('contact_form_modal.error'),
        type: 'error'
      });
    }
  });

  return mutation.mutate;
}
