import PropTypes from 'prop-types';

import { measureSourceProps } from './meters';

export const gasMetersProps = PropTypes.exact({
  id: PropTypes.string,
  number: PropTypes.string,
  zoneIds: PropTypes.arrayOf(PropTypes.string),
  postcode: PropTypes.string,
  createdAt: PropTypes.string,
  gasMeterTechnicalData: PropTypes.shape({
    id: PropTypes.string,
    gasMeterNumber: PropTypes.string,
    frequency: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
  }),
  gasMeterContractualData: PropTypes.shape({
    id: PropTypes.string,
    gasMeterNumber: PropTypes.string,
    transportationTariff: PropTypes.string,
    createdAt: PropTypes.string
  }),
  gasMeterUserAttestationRequest: PropTypes.exact({
    createdAt: PropTypes.string,
    status: PropTypes.string,
    userName: PropTypes.string
  }),
  zones: PropTypes.array,
  measureSource: measureSourceProps,
  isRemoteReadable: PropTypes.bool,
  hasData: PropTypes.bool,
  isUsable: PropTypes.bool
});

export const gasMetersListProps = PropTypes.shape({
  data: PropTypes.arrayOf(gasMetersProps)
});
