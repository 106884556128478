import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from './Tooltip';
import { WrapperInfo } from './Tooltip.style';

export function TooltipInfo({
  title,
  position = 'absolute',
  placement = 'top',
  ...props
}) {
  return (
    <Tooltip title={title}>
      <WrapperInfo position={position} placement={placement} {...props}>
        <FontAwesomeIcon icon={['fas', 'info-circle']} />
      </WrapperInfo>
    </Tooltip>
  );
}

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  position: PropTypes.string,
  placement: PropTypes.string
};
