import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Modal from 'eficia/components/organisms/Modal/Modal';
import { UserContext } from 'eficia/contexts/UserProvider';
import FormInput from 'eficia/views/forms/FormInput';
import FormMultiSelect from 'eficia/views/forms/FormMultiSelect';
import useContact from 'eficia/views/login/services/useContact';

import { CONTACT_FORM_OBJECTS } from '../entity/utilities/constants';

export default function ContactFormModal({ onClose, ...rest }) {
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);

  const contact = useContact();

  const formProps = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
      object: ''
    },
    shouldUnregister: false
  });
  const {
    formState,
    trigger,
    control,
    handleSubmit: onUseFormSubmit,
    formState: { errors },
    watch
  } = formProps;

  const message = watch('message', '');

  const handleContact = () => {
    contact({
      message: watch('message'),
      object: watch('object'),
      email: userData.email
    });
    onClose();
  };

  const objectsArray = JSON.parse(JSON.stringify(CONTACT_FORM_OBJECTS));

  return (
    <Modal
      onSubmit={onUseFormSubmit(handleContact)}
      onClose={onClose}
      isSubmitButtonDisabled={!formState.isValid || !formState.isDirty}
      testId="contact-form-modal"
      {...rest}
    >
      <Grid container justifyContent="center">
        <Grid item xs={10} className="mb-2">
          <Typography>
            <Trans i18nKey="contact_form_modal.content" />
          </Typography>
        </Grid>
        <Grid item xs={10} className="mt-2">
          <FormMultiSelect
            mode="simpleSelect"
            withSearch={false}
            formProps={formProps}
            name="object"
            data={objectsArray}
            placeholder={t('contact_form.object.label')}
            validator={{ required: true }}
            menuWidth={473}
          />
          {errors.message?.type === 'required' && (
            <span role="alert">{t('contact_form_modal.required_msg')}</span>
          )}
        </Grid>
        <Grid item xs={10} className="mt-2">
          <FormInput
            autoFocus
            placeholder={t('contact_form.message.placeholder')}
            name="message"
            control={control}
            error={errors.message}
            maxLength={800}
            multiline
            rows={10}
            required
            rules={{
              required: true
            }}
            onChange={() => {
              trigger('message');
            }}
          />
          {message.length >= 800 ? (
            <span style={{ color: 'red', float: 'right' }}>
              {t('contact_form_modal.content.length', {
                length: message.length
              })}
            </span>
          ) : (
            <span style={{ color: 'black', float: 'right' }}>
              {t('contact_form_modal.content.length', {
                length: message.length
              })}
            </span>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
}

ContactFormModal.propTypes = {
  onClose: PropTypes.func.isRequired
};
